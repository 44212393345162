<template>
    <div>
        <draggable 
            v-if="!loading"
            :list="newColumns" 
            :animation="200" 
            ghost-class="column" 
            handle=".hamburger-icon"
            group="columns-group" 
            class="draggable-table"
            @end="handleOrderChanged">
            <div class="column" v-for="column, key in newColumns" :key="key" :style="column.style">
                <div class="data-field label">
                    <q-icon type="hamburger-menu" class="hamburger-icon" color="black" width="12"></q-icon>
                    <span>{{column.label}}</span>
                </div>
                <div class="data-field" style="padding: 0 15px;" v-for="row, key in data" :key="key">
                    {{getFieldData(row, column)}}
                </div>
            </div>
        </draggable>
        <q-table
            v-else
            :data="[]"
            :columns="loadingColumns"
            loading
            :loadingRows="5"
        ></q-table>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'draggable-table',
    components: {
        draggable
    },
    data() {
        return {
            newColumns: [],
            loadingColumns: [
                {
                    label: '',
                    field: ''
                }
            ]
        }
    },
    props: {
        columns: {
            type: Array
        },
        data: {
            type: Array
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFieldData(row, column) {
            let field = row[column.field]

            if (!field) {
                field = row[`display-${column.projection.id}`]
            }

            return field;
        },
        handleOrderChanged(e) {
            this.$emit('input', this.newColumns)
        },
        updateColumns() {
            const percentage = Math.round(100 / this.columns.length)
            this.newColumns = this.columns
                .map(column => {
                    return {
                        ...column,
                        style: `flex: 0 1 ${percentage}%`
                    }
                })
        }
    },
    watch: {
        'columns': function() {
            this.updateColumns();
        }
    },
    mounted() {
        this.updateColumns();
    }
}
</script>

<style lang="scss" scoped>

.column {
    
}

.draggable-table {
    display: flex;
}

.data-field {
    user-select: none;
    font-family: Gotham;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    color: #212529;

    height: 50px;

    padding: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.label {
    display: flex;
    align-items: center;
    
    color: #495057;

    font-family: Gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */

    font-size: 10px;
    font-weight: 500;
    border-bottom: 1px solid #DEE2E6;

    overflow: hidden;   
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    height: 35px;

    .hamburger-icon {
        margin: 0 5px;
        cursor: grab;
    }    
}

</style>