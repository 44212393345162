<template>
    <div class="project-page detail-page-padding" id="overflow">
        <div v-if="!projectLoading">
            <div class="head">
                <div class="left">
                    <div class="project-name">
                        <h2>{{ projectName }}</h2>
                        <p v-if="subProjectName" class="sub-name">{{ subProjectName }}</p>
                    </div>
                    <div class="status">
                        <q-tooltip position="top">
                            <template v-slot:tooltip>Project status</template>
                            <q-tag size="medium" :variation="project.status !== 'finished' ? 'warning' : 'success'">{{ $t(`projectStatus.${project.status}`) }}</q-tag>
                        </q-tooltip>
                    </div>
                </div>
                <div class="right">
                    <div class="share" v-if="!isGeust()">
                        <q-avatar-group :images="shared" :max="4" class="avatars"></q-avatar-group>
                        <q-button
                            v-if="canEditProjectSettings && canEditMembers"
                            icon="cog"
                            variation="ghost"
                            size="small"
                            class="ml-m"
                            @click="loadSettings('/settings')"
                        >Beheer en toegang</q-button>
                    </div>
                </div>
            </div>

            <div class="project">
                <div class="left">
                    <div class="panel q-card-small default" v-if="defaultContext">
                        <h4>{{ $t(defaultContext.name) }}</h4>
                        <div
                            v-for="indicator in defaultContext.indicators"
                            :key="indicator.id"
                            :class="['indicator', editing == indicator.componentId ? 'selected' : '']"
                        >
                            <component
                                :is="getCustomIndicatorRow(indicator)"
                                :project="project"
                                :indicator="indicator"
                                :disabled="!canEditIndicator(indicator)"
                                :isEditing="editing == indicator.componentId"
                                :loading="projectLoading"
                                @updateProject="handleUpdateProject"
                                @saveIndicator="onSaveIndicator"
                                @startLoading="onStartLoading"
                                @stopLoading="onStopLoading"
                                @edit="edit"
                                @cancel="editing = ''"
                                :data-testid="'input-' + indicator.answerType"
                            >
                            </component>
                        </div>
                        <div v-if="canViewTemplate" class="indicator">
                            <select-project-template
                                :project="project"
                                :isEditing="editing == 'project-template'"
                                :loading="projectLoading"
                                @startLoading="onStartLoading"
                                @stopLoading="onStopLoading"
                                @edit="edit"
                                @projectTemplateChosen="projectTemplateChosen"
                            ></select-project-template>
                        </div>
                    </div>

                    <context-card
                        v-if="projectDetailsContext"
                        class="default"
                        :context="projectDetailsContext"
                        :indicators="projectDetailsContext.indicators"
                        :allIndicators="projectDetailsContext.indicators"
                        :editing="editing"
                        :projectProp="project"
                        :duplicatingEnabled="false"
                        @contextUpdated="handleContextUpdated"
                        @editIndicator="edit"
                        @saveIndicator="onSaveIndicator"
                        @startLoading="onStartLoading"
                        @stopLoading="onStopLoading">
                    </context-card>

                    <div v-else-if="project.usesCrowFlow" class="panel q-card-small default loading-panel">
                        <div class="blank-title"></div>
                        <div class="indicator" v-for="n in 5" :key="n">
                            <div class="name blank-row"></div>
                            <div class="answer blank-row"></div>
                        </div>
                    </div>
                </div>
                        
                <div class="right">
                    <formtimeline
                        v-if="showTimeline"
                        class="margin-right"
                        :options="options"
                        :settings="project.settings"
                        :project="project"
                        :useBPReference="useBPReference"
                        :discussedPsuReference="discussedPsuReference"
                        width="540px"
                        :canFinish="hasAnsweredRequiredIndicators"
                        :isFinished="project.status === 'finished'"
                        :finishedDate="project.finishedDate"
                        :editingFormTimeline="editingFormTimeline"
                        :formTemplates="formTemplates"
                        :projectJwt="projectJwt"
                        :canAdminAgree="canAdminAgree"
                        @clickForm="clickForm"
                        @clickEnquete="clickEnquete"
                        @clickNewMeasurement="clickNewMeasurement"
                        @clickNewEnquete="clickNewEnquete"
                        @enqueteUpdated="handleEnqueteUpdated"
                        @duplicateEnquete="duplicateEnquete"
                        @publish="publishProject"
                        @finalize="finishProject"
                        @answerIndicator="answerIndicator"
                        @clickReferenceLetter="clickReferenceLetter"
                        @onPdUsesCrowFlowChanged="onPdUsesCrowFlowChanged"
                        @statusUpdated="setProjectStatus"
                        @reloadForms="getProjectForms"
                        ref="timeline"
                    ></formtimeline>

                    <div class="on-not-set" v-else>
                        <q-zerostate class="mt-xxl" img="/statics/img/building_gradient.svg">
                            <div v-if="creatingForms">
                                <span>Er worden formulieren aangemaakt, dit kan even duren.</span>
                                <div class="loader-wrapper">
                                    <div class="loader"></div>
                                </div>
                            </div>
                            <div v-else-if="!initializedForms && !creatingForms && crowProjectIsReady">
                                <span>Formulieren worden opgehaald, dit kan even duren.</span>
                                <div class="loader-wrapper">
                                    <div class="loader"></div>
                                </div>
                            </div>
                            <div v-else-if="!initializedForms && !creatingForms">
                                <span>Het project wordt opgehaald, dit kan even duren.</span>
                                <div class="loader-wrapper">
                                    <div class="loader"></div>
                                </div>
                            </div>
                            <div v-else-if="
                                    canViewTemplate && 
                                    (!project.templates || !project.templates.length) || 
                                    loadingComponentId === 'project-template'
                                " 
                                class="select-templates-wrapper">
                                <q-button
                                    @click="editing = 'project-template'"
                                    :loading="loadingComponentId === 'project-template'"
                                >Projecttemplates selecteren</q-button
                                >
                            </div>
                            <q-button
                                v-else-if="
                                    canEditIndicator(clientReference) && 
                                    !clientReference.answer || 
                                    loadingComponentId === clientReference.componentId
                                "
                                @click="editing = clientReference.componentId"
                                :loading="loadingComponentId === clientReference.componentId"
                                >{{ $t('Client') }} selecteren</q-button
                            >
                            <q-button
                                v-else-if="
                                    canEditIndicator(contractorReference) && 
                                    !contractorReference.answer || 
                                    loadingComponentId === contractorReference.componentId
                                "
                                @click="editing = contractorReference.componentId"
                                :loading="loadingComponentId === contractorReference.componentId"
                                >{{ $t('Contractor') }} selecteren</q-button
                            >
                            <div v-else-if="!isInitiator && !creatingForms && !crowProjectIsReady">
                                <span>De {{ organisationType === 'client' ? 'opdrachtnemer' : 'opdrachtgever' }} moet het project nog verder configureren</span>
                            </div>
                            <div v-else-if="project.usesCrowFlow && !projectDetailsContext">
                                <span>Better Performance gegevens ophalen</span>
                                <div class="loader-wrapper">
                                    <div class="loader"></div>
                                </div>
                            </div>
                            <q-button
                                v-else-if="
                                    canEditIndicator(projectTypeReference) && 
                                    !projectTypeReference.answer || 
                                    loadingComponentId === projectTypeReference.componentId
                                "
                                @click="editing = projectTypeReference.componentId"
                                :loading="loadingComponentId === projectTypeReference.componentId"
                                >Better Performance type selecteren</q-button
                            >
                            <q-button
                                v-else-if="
                                    canEditIndicator(formVersionReference) && 
                                    !formVersionReference.answer && 
                                    !formVersionReference.notInProject ||
                                    loadingComponentId === formVersionReference.componentId
                                "
                                @click="editing = formVersionReference.componentId"
                                :loading="loadingComponentId === formVersionReference.componentId"
                                >Formulier versie selecteren</q-button
                            >
                            <q-button
                                v-else-if="
                                    canEditIndicator(durabilityReference) && 
                                    !isValidAnswer(durabilityReference.answer) && 
                                    !durabilityReference.notInProject || 
                                    loadingComponentId === durabilityReference.componentId
                                "
                                @click="editing = durabilityReference.componentId"
                                :loading="loadingComponentId === durabilityReference.componentId"
                                >Duurzaamheid selecteren</q-button
                            >
                            <q-button
                                v-else-if="
                                    amsterdamPricingRelevant && 
                                    canEditIndicator(amsterdamPricingReference) && 
                                    !isValidAnswer(amsterdamPricingReference.answer) ||
                                    loadingComponentId === amsterdamPricingReference.componentId
                                "
                                @click="editing = amsterdamPricingReference.componentId"
                                :loading="loadingComponentId === amsterdamPricingReference.componentId"
                                >Prijsvorming selecteren</q-button
                            >
                            <div class="invite-contactperson" v-else-if="hasToInviteContactperson && hasContactpersonInCrowMeta">
                                <p class="invite-contactperson-text">{{ inviteContactpersonText }}</p>
                                <div class="invite-contactperson-button">
                                    <q-button @click="inviteContactpersonInCrowMeta" :loading="invitingContactperson">
                                        {{
                                            project.crowMeta.contactpersonName
                                                ? project.crowMeta.contactpersonName
                                                : project.crowMeta.contactpersonEmail
                                        }}
                                        uitnodigen
                                    </q-button>
                                </div>
                            </div>
                            <div class="invite-contactperson" v-else-if="hasToInviteContactperson && !project.status === 'finishing'">
                                <p class="invite-contactperson-text">{{ inviteContactpersonText }}</p>
                                <div class="invite-contactperson-button">
                                    <q-button @click="inviteContractor">Contactpersoon uitnodigen</q-button>
                                </div>
                            </div>
                            <div v-else-if="hasToInviteContactperson && project.status === 'finishing'">
                                <p>Ga naar 'Beheer en toegang' en voeg een contactpersoon van de andere partij toe om de formulieren toegankelijk te maken.</p>
                            </div>
                            <div v-else>
                                <span>{{ zeroFormsText }}</span>
                            </div>
                        </q-zerostate>
                    </div>

                    <div
                        class="collaboration-contractor"
                        v-if="project.clientStatus === 'finished' && collaborationContractorReference.answer"
                    >
                        <p class="label">
                            Reactie {{ project.contractor.name }}
                            <q-tooltip position="top">
                                <template v-slot:tooltip
                                    ><p>
                                        Algemene reactie van de contactpersoon van {{ project.contractor.name }} over
                                        <br />
                                        de samenwerking met {{ project.client.name }} in dit project.
                                    </p></template
                                >
                                <div class="question-circle"><q-icon type="QuestionCircle" width="14"></q-icon></div>
                            </q-tooltip>
                        </p>
                        <p class="answer">{{ collaborationContractorReference.answer }}</p>
                    </div>
                </div>
            </div>

            <div v-if="referenceConversion.variation !== 'default'" class="options">
                <div>
                    <div class="title">Projectkenmerken</div>
                    <div class="info">
                        <q-tooltip :disabled="!referenceConversion.tooltip" position="top">
                            <template v-slot:tooltip>
                                <p v-html="referenceConversion.tooltip"></p> 
                            </template>
                            <q-tag :variation="referenceConversion.variation" size="small">{{
                                referenceConversion.conversion
                            }}</q-tag>
                        </q-tooltip>
                    </div>
                </div>

                <div class="search">
                    <q-icon type="Search"></q-icon>
                    <div class="not-searching" v-if="!searching" @click="startSearching">
                        <span>Zoek een kenmerk</span>
                    </div>
                    <input
                        v-else
                        ref="search"
                        type="text"
                        class="input"
                        @input="handleSearchInput"
                        placeholder="e.g. contractvorm"
                        autofocus
                        @blur="searching = !!search"
                        :disabled="isDuplicating"
                    />
                </div>
            </div>
            <context-overview
                class="contexts-container"
                :rows="2"
                :indicators="otherIndicators"
                :project="project"
                :editing="editing"
                :search="search"
                :duplicatingEnabled="project.status !== 'finished'"
                @editIndicator="edit"
                @saveIndicator="onSaveIndicator"
                @startLoading="onStartLoading"
                @stopLoading="onStopLoading"
                @removeIndicator="handleIndicatorRemoved"
                @contextUpdated="handleContextUpdated"
                @setDuplicating="setDuplicatingState"
                @indicatorsAdded="handleIndicatorsAdded"
            ></context-overview>
            <!-- <div class="contexts-wrapper">
                <div class="left">
                    <context-card
                        v-for="context in filteredOtherContexts.filter((c, i) => i % 2 === 0)"
                        :key="filteredContextIndicators[context.name].map(indicator => indicator.id).join('-')"
                        :context="context"
                        :indicators="filteredContextIndicators[context.name]"
                        :editing="editing"
                        :projectProp="project"
                        :search="search"
                        @removeIndicator="handleIndicatorRemoved"
                        @getProjectData="getProjectData"
                        @contextUpdated="handleContextUpdated"
                        @editIndicator="edit"
                        @saveIndicator="onSaveIndicator"
                        @setDuplicating="(duplicating) => (isDuplicating = duplicating)"
                        @indicatorsAdded="handleIndicatorsAdded($event, context)"
                    >
                    </context-card>
                </div>
                <div class="right">
                    <context-card
                        v-for="context in filteredOtherContexts.filter((c, i) => i % 2 !== 0)"
                        :key="filteredContextIndicators[context.name].map(indicator => indicator.id).join('-')"
                        :context="context"
                        :indicators="filteredContextIndicators[context.name]"
                        :editing="editing"
                        :projectProp="project"
                        :search="search"
                        @removeIndicator="handleIndicatorRemoved"
                        @getProjectData="getProjectData"
                        @contextUpdated="handleContextUpdated"
                        @editIndicator="edit"
                        @saveIndicator="onSaveIndicator"
                        @setDuplicating="(duplicating) => (isDuplicating = duplicating)"
                        @indicatorsAdded="handleIndicatorsAdded($event, context)"
                    >
                    </context-card>
                </div>
            </div> -->
        </div>
        <project-loading-state v-else></project-loading-state>
        <publish-project
            v-if="publishingProject"
            :project="project"
            :endDateReferenceId="endDateReference.id"
            :invoicedSumReferenceId="invoicedSumReference.id"
            :formId="project.referencesId"
            :responseId="project.referencesResponseId"
            :canAdminAgree="canAdminAgree"
            @close="publishingProject = false"
            @reload="reload"
        >
        </publish-project>
    </div>
</template>

<script>
import _ from 'lodash';
import jwtDecode from 'jwt-decode';

import FormTimeline from './FormTimeline';
import IndicatorAnswer from './IndicatorAnswer';
import CrowIndicatorAnswer from './customindicatoranswer/CrowIndicatorAnswer';
import ProjectLoadingState from './ProjectLoadingState';
import PublishProject from './PublishProject';
import ContextCard from './ContextCard.vue';
import ContextOverview from './ContextOverview.vue';
import SelectProjectTemplate from './SelectProjectTemplate.vue';

import { isValidAnswer, userInitials, companyInitials, sortComponents } from '@/assets/js/utils.js';

import { ORGANISATION_SMALL, 
    GET_PROJECT_REFERENCES, 
    GET_PROJECT_FORMS, 
    GET_FORM_TEMPLATES, 
    ACCESS_PROJECT, 
    GET_PROJECT_STATUS
} from '../../../graphql/queries';

import { 
    SAVE_VALUE_VALUE, 
    ADD_CROW_FORM, 
    PROJECT_ADD_MEMBER, 
    PROJECT_REPAIR_FORMIDS, 
    DUPLICATE_FORM, 
    CREATE_FORM, 
    FINISH_PROJECT,
    UPDATE_FORM
} from '../../../graphql/mutations';

export default {
    components: {
        formtimeline: FormTimeline,
        'indicator-answer': IndicatorAnswer,
        'crow-indicator-answer': CrowIndicatorAnswer,
        'project-loading-state': ProjectLoadingState,
        'publish-project': PublishProject,
        'select-project-template': SelectProjectTemplate,
        ContextCard,
        ContextOverview
    },
    data() {
        return {
            projectLoading: true,
            project: {
                name: this.$route.query.title || '',
                settings: {
                    categories: [],
                },
                forms: [],
                templates: []
            },
            initializedForms: false,
            initializedFormTemplates: false,
            editing: '',
            searching: false,
            search: '',
            referenceConversion: {
                conversion: '0/0',
                variation: 'default',
            },
            shared: [],
            editingFormTimeline: false,
            publishingProject: false,
            inviteContactpersonText: 'Nodig je contactpersoon bij de opdrachtnemer uit om de meting te kunnen beginnen',
            invitingContactperson: false,
            repairingFormIds: false,
            isDuplicating: false,
            creatingForms: false,
            formTemplates: [],
            forms: [],
            projectJwt: undefined,
            client: null,
            contractor: null,
            branch: null, 
            isCrowMasterOrganisation: this.$store.getters.getCurrentOrganisation.id === 'crow-master-organisation',
            otherIndicators: [],
            loadingComponentId: null
        };
    },
    methods: {
        handleIndicatorsAdded(project) {
            this.project.references.components = project.references.components;
            this.project = this.setProject({ ...this.project });
            this.updateReferenceConversion();
        },
        async projectTemplateChosen(templates) {
            this.project.templates = templates;
            this.editing = null;

            this.getProjectComponents();
            this.setProjectStatus();
        },
        getProjectData() {
            this.getProject();
        },
        inviteContactpersonInCrowMeta() {
            this.invitingContactperson = true;

            this.$apollo
                .mutate({
                    mutation: PROJECT_ADD_MEMBER,
                    variables: {
                        id: this.project.id,
                        email: this.project.crowMeta.contactpersonEmail,
                        roles: ['admin'],
                        organisationId: this.project.contractor.id,
                    },
                })
                .then(() => {
                    this.invitingContactperson = false;

                    this.$router.go();
                })
                .catch((error) => {
                    this.invitingContactperson = false;
                    this.$store.commit('notify', { type: 'danger', message: 'Er is iets misgegaan' });
                });
        },
        inviteContractor() {
            this.$router.push(`/projects/${this.project.id}/settings?invite=contractor`);
        },
        isGeust() {
            if(this.projectLoading) return false
            return this.projectAbility.isGeust();
        },
        reload() {
            this.resetJwt();
            this.initializedForms = false;
            this.client = null;
            this.contractor = null;
            this.branch = null;
            this.project = {
                name: this.$route.query.title || '',
                settings: {
                    categories: [],
                },
                forms: [],
                templates: [],
                indicators: []
            };
            this.initializedFormTemplates = false;
            this.getProject();
        },
        answerIndicator(indicator, answer) {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectJwt = this.getProjectJwt();

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_VALUE,
                    variables: {
                        componentId: indicator.componentId,
                        responseId: this.project.referencesResponseId,
                        formId: this.project.referencesId,
                        indicatorId: indicator.id,
                        value: answer,
                        organisationId,
                        projectJwt
                    },
                })
                .then(result => {
                    const value = result.data.value_setValue;
                    this.onSaveIndicator({ value, indicator });
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout, probeer dit opnieuw' });
                    this.$refs.timeline.resetIndicatorLoading();
                });
        },
        publishProject() {
            this.publishingProject = true;

        },
        finishProject() {
            this.$apollo.mutate({
                mutation: FINISH_PROJECT,
                variables: {
                    id: this.project.id
                }
            })
            .then(result => {
                const { clientStatus, contractorStatus } = result.data.project_finish;
                this.project.clientStatus = clientStatus;
                this.project.contractorStatus = contractorStatus;
                this.project.status = this.organisationType === 'client' ? clientStatus : contractorStatus;  
                this.getProject();
            })
        },
        edit(indicator) {
            if (!indicator) return (this.editing = '');
            this.editing = indicator.componentId;
        },
        isValidAnswer(answer) {
            return isValidAnswer(answer)
        },
        startSearching() {
            this.searching = true;
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        setProject(project) {
            if (!project.references.components)
                throw new Error(`An error occured, the references form should have components!`);

            // if (project.references.components.some(component => component.indicator.id === 'client'))

            //     throw new Error(`An error occured while initializing the project.
            //             Answered indicators could not be matched with the indicators provided by the form template.
            //             It is likely the masterset is incomplete. Try syncing defaulttemplates in BO or refer to a developer.`)

            let components = project.references.components;
            if(!this.hasQfactPD) components = components.filter(component => ['default-context','crow-project-details'].includes(component.indicator.context.id));

            const sortedComponents = sortComponents(components);

            project.indicators = sortedComponents
                .map((component) => {
                    const indicator = {
                        ...component.indicator,
                        componentId: component.id,
                        display: component.display,
                        disabled: component.disabled,
                    };
                    return indicator
                })
                .filter(indicator => indicator.type === 'reference' && !indicator.disabled);

            const response = project.references.responses[0];

            project.referencesId = project.references.id;
            if (response) project.referencesResponseId = response.id;
            else project.referencesResponseId = null;

            project.indicators.forEach((indicator) => {
                indicator.answer = null;

                if (response) {
                    const componentIndex = response.values
                        .map((value) => value.componentId)
                        .indexOf(indicator.componentId);
                    if (componentIndex !== -1) {
                        indicator.answer = response.values[componentIndex].value;
                        if(['client','contractor','contractor-branch'].includes(indicator.masterId)) {
                            indicator.answerValues = this.getOrganisationAnswerValues(indicator)
                        }
                    }
                }
            }); 

            this.otherIndicators = project.indicators.filter((indicator) => indicator.context?.default !== true && indicator.context.id !== 'crow-project-details');

            if (project.clientStatus === 'finished') {
                const invoicedSumIndex = project.indicators
                    .map((indicator) => indicator.masterId)
                    .indexOf('invoiced-sum');

                if (invoicedSumIndex !== -1) delete project.indicators[invoicedSumIndex].display;
            }

            project.isMigrated = (project.crowMeta && project.crowMeta.ProjectID) != null;

            const projectMasterIds = project.indicators.map(indicator => indicator.masterId);
            const projectCrowIndicator = 'crow-type';
            const hasCrowIndicators = projectMasterIds.includes(projectCrowIndicator);

            if((!project.hasChosenCrowFlow && hasCrowIndicators) || project.pdHybridmode) {
                project.usesCrowFlow = true;
                project.hasChosenCrowFlow = true;
            }

            const status = this.organisationType === 'client' ? project.clientStatus : project.contractorStatus;
            project.status = status || project.status;

            project.indicators = project.indicators.map(indicator => {
                indicator.canEdit = this.canEditIndicator(indicator);
                return indicator
            })

            return project;
        },
        getOrganisationAnswerValues(indicator) {
            let organisation = null;
            switch(indicator.masterId) {
                case'client':
                    if(this.client) organisation = this.client;
                    break
                case'contractor':
                    if(this.contractor) organisation = this.contractor;
                    break
                case'contractor-branch':
                    if(this.branch) organisation = {
                        ...this.branch,
                        name: this.extractBranch(this.branch)
                    };
                    break
                default:
                    break
            }
            if(organisation) return [
                {
                    label: organisation.name,
                    value: organisation.id
                }
            ]

            this.setOrganisationAnswerValues(indicator)
            return []
        },
        async setOrganisationAnswerValues(indicator) {
            let answerValues = [];

            if(indicator.masterId === 'client') {
                let client = this.client;
                if(!client) client = await this.$apollo.query({
                    query: ORGANISATION_SMALL,
                    variables: {
                        id: indicator.answer,
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => result.data.organisation);
                this.client = client;
                answerValues = [
                    {
                        label: client.name,
                        value: client.id
                    }
                ]
            } else if(indicator.masterId === 'contractor') {
                let contractor = this.contractor;
                if(!contractor) contractor = await this.$apollo.query({
                    query: ORGANISATION_SMALL,
                    variables: {
                        id: indicator.answer,
                    },
                })
                .then(result => result.data.organisation);
                this.contractor = contractor;
                answerValues = [
                    {
                        label: contractor.name,
                        value: contractor.id
                    }
                ]
            } else if(indicator.masterId === 'contractor-branch') {
                let branch = this.branch;
                if(!branch) branch = await this.$apollo.query({
                    query: ORGANISATION_SMALL,
                    variables: {
                        id: indicator.answer,
                    },
                })
                .then(result => result.data.organisation);
                this.branch = branch;
                answerValues = [
                    {
                        label: this.extractBranch(branch),
                        value: branch.id
                    }
                ]
            }

            const index = this.project.indicators.findIndex(projectIndicator => projectIndicator.id === indicator.id);
            if(index === -1) return
            this.project.indicators[index].answerValues = answerValues;
        },
        extractBranch(organisation) {
            let line1 = organisation.address.line1;
            let city = organisation.address.city;
            const emptyLine1 = line1 == null || line1 == 'null';
            const emptyCity = city == null || city == 'null';

            if(emptyLine1 && emptyCity) return organisation.legal.branchId || 'Geen gegevens'

            line1 = line1?.replace(' null', '').trim();
            city = city?.replace(' null', '').trim();

            const data = [line1, city];
            const filteredData = data.filter(data => Boolean(data));

            return filteredData.join(', ')
        },
        handleUpdateProject(updatedData) {
            this.project = { ...this.project, ...updatedData };
            this.updateReferenceConversion();
            this.updateShared();
        },
        handleContextUpdated(project) {
            this.project = this.setProject(project);

            this.updateReferenceConversion();
        },
        setSummaryProject(project) {
            const forms = this.forms;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            const finalForms = forms.filter((form) => form.name === 'Eindmeting');

            const myForm = finalForms.find(
                (form) => form.organisationId === organisationId || form.organisationId === project.client.id
            );
            const companionForm = finalForms.find(
                (form) => form.organisationId !== organisationId || form.organisationId === project.contractor.id
            );

            const clientForm = this.organisationType === 'client' ? myForm : companionForm;
            const contractorForm = this.organisationType === 'contractor' ? myForm : companionForm;

            project.indicators = [
                ...project.indicators,
                {
                    id: 'client-score',
                    name: `Behaalde score ${project.contractor.name}`.substring(0, 20) + '...',
                    componentId: 'component-client-score',
                    context: {
                        id: 'crow-project-details',
                        name: 'Crow_project_details',
                        description: '',
                        default: false,
                    },
                    protectionLevel: 'private_shared',
                    answer: clientForm.score,
                },
                {
                    id: 'contractor-score',
                    name: `Behaalde score ${project.client.name}`.substring(0, 20) + '...',
                    componentId: 'component-contractor-score',
                    context: {
                        id: 'crow-project-details',
                        name: 'Crow_project_details',
                        description: '',
                        default: false,
                    },
                    protectionLevel: 'private_shared',
                    answer: contractorForm.score,
                },
            ];

            project.settings.categories = project.settings.categories
                .filter((category) => category.id === 'final-measurement')
                .map((category) => {
                    return {
                        ...category,
                        dependsOn: [],
                    };
                });

            return project;
        },
        loadSettings(path = '/settings') {
            this.$router.push({
                path: `/projects/${this.$route.params.id}${path}`,
                query: { title: this.project.name },
            });
        },
        clickForm(form) {
            this.$router.push('/forms/' + form.id);
        },
        clickEnquete(form) {
            this.$router.push('/enquetes/' + form.id);
        },
        clickReferenceLetter({ referenceLetterId }) {
            this.$router.push(`/projects/${this.project.id}/letter/${referenceLetterId}`);
        },
        handleEnqueteUpdated(form) {
            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: form.id,
                    name: form.name
                }
            })
            .then(result => {
                this.$store.commit('notify', { type: 'success', message: 'Formuliernaam succesvol aangepast' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanpassen van het formulier' })
            })
        },
        async clickNewEnquete(form) {
            const { name, template, categoryId } = form;

            this.editingFormTimeline = true;
            this.$apollo.mutate({
                mutation: CREATE_FORM,
                variables: {
                    name,
                    templateId: template.id,
                    organisationId: this.$store.getters.getCurrentOrganisation.id,
                    projectId: this.$route.params.id,
                    status: 'concept',
                    categoryId
                }
            })
            .then(result => {
                this.editingFormTimeline = false;
                const createdForm = result.data.form_create
                this.project.forms.push(createdForm)
                this.project.formIds.push(createdForm.id)
                this.project.settings.categories = this.project.settings.categories.map(category => {
                    if(category.id !== 'intermediate-measurement') return category
                    category.formIds = category.formIds || [];
                    category.formIds.push(createdForm.id);

                    return category
                });
                this.setProjectStatus();
            })
            .catch(error => {
                this.editingFormTimeline = false;
                console.log(error)
            })
        },
        duplicateEnquete(formId, name) {
            this.editingFormTimeline = true;
            this.$apollo.mutate({
                mutation: DUPLICATE_FORM,
                variables: {
                    formId,
                    name,
                    projectId: this.project.id,
                    categoryId: 'intermediate-measurement'
                }
            })
            .then(result => {
                this.editingFormTimeline = false;
                const createdForm = result.data.form_duplicated;
                this.project.forms.push(createdForm)
                this.project.formIds.push(createdForm.id)
                this.project.settings.categories = this.project.settings.categories.map(category => {
                    if(category.id !== 'intermediate-measurement') return category 
                    category.formIds.push(createdForm.id);

                    return category
                })
            })
            .catch(error => {
                this.editingFormTimeline = false;
                console.log(error)
            })
        },
        clickNewMeasurement(form) {
            const name = 'Tussentijdse meting';
            const { categoryId } = form;
            const projectId = this.project.id;
            const clientId = this.clientReference.answer;
            const contractorId = this.contractorReference.answer;
            const crowType = this.projectTypeReference.answer;
            const formVersion = this.formVersionReference?.answer || '2018';
            const durability = this.durabilityReference?.answer || false;
            const amsterdamPricing = this.amsterdamPricingReference.answer || false;

            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            this.editingFormTimeline = true;

            this.$apollo
                .mutate({
                    mutation: ADD_CROW_FORM,
                    variables: {
                        name,
                        projectId,
                        clientId,
                        contractorId,
                        crowType,
                        formVersion,
                        durability,
                        categoryId,
                        organisationId,
                        amsterdamPricing
                    }
                })
                .then(() => {
                    setTimeout(() => {
                        this.initializedForms = false;
                        this.getProject();
                    }, 500);
                })
                .catch(error => {
                    this.editingFormTimeline = false;
                    this.$store.commit('notify', {
                        type: 'danger',
                        message:
                            'Oeps, er ging iets mis. Probeer het nogmaals of neem contact op met de Better Performance.',
                    });
                });
        },
        /**
         * This method is called whenever a reference (indicator) is set or updated.
         * Use this method as an event for custom behaviour
         */
        onSaveIndicator({ value, indicator }) {
            this.onStopLoading(indicator);
            this.project.referencesResponseId = value.responseId;
            this.project.indicators = this.project.indicators.map(projectIndicator => {
                if(projectIndicator.componentId === indicator.componentId) {
                    projectIndicator.answer = value.value;
                    if(['client','contractor','contractor-branch'].includes(indicator.masterId)) {
                        projectIndicator.answerValues = this.getOrganisationAnswerValues(projectIndicator);
                    }
                }
                return projectIndicator
            })

            this.updateReferenceConversion();
            this.updateShared();

            if (this.project.status === 'new' || (this.project.status === 'active' && this.hasAnsweredRequiredIndicators) || this.project.status === 'finishing') {
                this.setProjectStatus();
            }

            const isBPMasterIndicator = this.indicatorMasterIdCheck(this.bpMasterIds, indicator);
            if (isBPMasterIndicator && this.hasAnsweredRequiredBPIndicators && !this.loadingComponentId) this.handleGeneratingForms();
        },
        async handleGeneratingForms() {
            const GENERATING_FORMS_TIME = 1000;

            let hasBPForms = false;

            while(!hasBPForms) {
                await new Promise(r => setTimeout(r, GENERATING_FORMS_TIME));
                await this.getProjectForms();
                hasBPForms = this.project.forms.some(form => ['client','contractor'].includes(form.type));
            }

            this.creatingForms = false;
            this.setProjectStatus();
        },
        onStartLoading(indicator) {
            const { componentId } = indicator;
            this.loadingComponentId = componentId;
            this.project.indicators = this.project.indicators.map(projectIndicator => {
                if(projectIndicator.componentId !== componentId) return projectIndicator
                return indicator
            });

            const isBPMasterIndicator = this.indicatorMasterIdCheck(this.bpMasterIds, indicator);
            if (isBPMasterIndicator && this.hasAnsweredRequiredBPIndicators) this.creatingForms = true;
        },
        onStopLoading(indicator) {
            const { componentId } = indicator;
            if(this.loadingComponentId === componentId) this.loadingComponentId = null;
            this.project.indicators = this.project.indicators.map(projectIndicator => {
                if(projectIndicator.componentId !== componentId) return projectIndicator
                return indicator
            })
        },
        getCustomIndicatorRow(indicator) {
            if (['contractor', 'client', 'contractor-branch'].includes(indicator.masterId))
                return 'crow-indicator-answer';
            else return 'indicator-answer';
        },
        updateReferenceConversion() {
            const indicators = this.otherIndicators;
            if (!this.hasQfactPD || indicators.length === 0) {
                this.referenceConversion = {
                    conversion: '0/0',
                    variation: 'default',
                };
                return;
            }

            const total = indicators.length;
            const answered = indicators.filter(indicator => isValidAnswer(indicator.answer)).length;

            const required = indicators.filter(indicator => indicator.required).length;
            const requiredAnswered = indicators.filter(indicator => indicator.required && isValidAnswer(indicator.answer)).length;

            const conversion = `${answered}/${total}`;
            const variation = answered < total ? 'warning' : 'success';

            let tooltip = '';
            const totalText = `<b>${answered}</b> van de <b>${total}</b> kenmerken ingevuld<br>`;
            const requiredText = `<b>${requiredAnswered}</b> van de <b>${required}</b> verplichte kenmerken<span style="color:#00a1ae">*</span> ingevuld<br>`;
            if (total !== required) tooltip += totalText;
            if (required > 0) tooltip += requiredText;

            this.referenceConversion = {
                conversion,
                variation,
                tooltip
            };
        },
        /**
         * Updates the 'shared' field which is used in the avatar-group.
         * It formats related users and organisations into one array which is used in the avatar group.
         * It shows the contractor (if set) and client (if set) of the project,
         * it doesnt show invited users, or remotely added users (users which arent registered yet)
         * it doesnt show system admin, nor helpdesk admin.
         *
         * Also it removed duplicates
         */
        updateShared() {
            let items = [];

            if (this.project.members)
                this.project.members
                    .filter(
                        (member, key) =>
                            member.status !== 'invited' &&
                            member.status !== 'remote_added' &&
                            member.userId !== 'system-admin' &&
                            member.userId !== 'helpdesk-admin' &&
                            this.project.members.map((member) => member.userId).indexOf(member.userId) === key
                    )
                    .forEach((member) => {
                        if (!member.user.firstName) member.user.firstName = ' ';
                        if (!member.user.lastName) member.user.lastName = ' ';

                        items.push({
                            image: member.user.avatar,
                            tooltip: `${member.user.firstName} ${member.user.lastName}`,
                            fallback: userInitials(member.user.firstName, member.user.lastName)
                        });
                    });

            new Promise(async (resolve, reject) => {
                if (this.contractorReference.answer) {
                    let contractor = this.contractor;
                    if(!contractor) contractor = await this.$apollo.query({
                        query: ORGANISATION_SMALL,
                        variables: {
                            id: this.contractorReference.answer,
                        },
                    })
                    .then(result => result.data.organisation);

                    items.push({
                        image: contractor.logo,
                        tooltip: contractor.name,
                        fallback: companyInitials(contractor.name)
                    });
                }

                if (this.clientReference.answer && !this.project.private) {
                    let client = this.client;
                    if(!client) client = await this.$apollo.query({
                        query: ORGANISATION_SMALL,
                        variables: {
                            id: this.clientReference.answer,
                        },
                    })
                    .then(result => result.data.organisation);

                    items.push({
                        image: client.logo,
                        tooltip: client.name,
                        fallback: companyInitials(client.name)
                    });
                }

                resolve(items);
            }).then((result) => {
                this.shared = result;
            });
        },
        updateUserRoles() {
            const members = this.project.members || [];
            const user = this.$store.getters.getUser;
            const organisation = this.$store.getters.getCurrentOrganisation;
            const member = members.find(
                (member) => member.userId === user.id && member.organisationId === organisation.id
            );
            const isGeustOrganisation =
                this.project.client &&
                this.project.contractor &&
                organisation.id !== this.project.client.id &&
                organisation.id !== this.project.contractor.id;

            this.projectAbility.set(this.project.id, member, this.ability, isGeustOrganisation);
        },
        canEditIndicator(indicator) {
            if (this.isDuplicating || this.projectLoading) return false;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectOwnerOrganisationId = this.project.organisationId;
            const { protectionLevel } = indicator;
            const hasPermission = this.projectAbility.can('fill__Value__set_value');

            const canBePublished = this.indicatorMasterIdCheck(this.defaultMasterIds, indicator) || this.indicatorMasterIdCheck(this.crowMasterIds, indicator);
            if (canBePublished && this.project.clientStatus === 'finished') return false
            if (this.project.contractorStatus === 'finished') return false

            if(this.hasAnsweredRequiredBPIndicators && this.indicatorMasterIdCheck(this.bpMasterIds, indicator)) return false
            
            if(indicator.id === 'project-template' && 
                hasPermission && 
                this.organisationType === 'contractor' && 
                this.hasQfactPD) return true

            if (protectionLevel === 'client_shared' && this.organisationType !== 'client') return false;
            if (protectionLevel === 'contractor_shared' && this.organisationType !== 'contractor') return false;
            if (protectionLevel === 'public' && indicator.masterId === 'project-end-date' && !this.isInitiator) return false;

            if (
                (protectionLevel === 'private' || protectionLevel === 'private_shared') && organisationId !== projectOwnerOrganisationId
            )
                return false

            if (!hasPermission) return false

            return true;
        },
        clickReferenceLetter({ referenceLetterId }) {
            this.$router.push(`/projects/${this.project.id}/letter/${referenceLetterId}`);
        },
        isValidAnswer(answer) {
            return isValidAnswer(answer)
        },
        async setFormTemplates() {
            if(!this.hasPDFullProduct || 
                this.initializedFormTemplates || 
                !this.project.templates || 
                this.project.templates.length === 0 ||
                this.formTemplates.length > 0) return

            try {
                const formTemplates = await this.$apollo.query({
                    query: GET_FORM_TEMPLATES,
                    variables: {
                        where: {
                            AND: [
                                {
                                    organisationId: this.$store.getters.getCurrentOrganisation.id
                                },
                                {
                                    type__nin: ["reference"]
                                },
                                {
                                    answerType: 'survey'
                                },
                                {
                                    status: 'active'
                                }
                            ]
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => result.data.formtemplates || []);

                const questionIndicators = this.getProjectQuestions();

                const templatesWithDisabled = formTemplates.map(template => {
                    const templateIndicatorIds = template.components.map(component => component.indicatorId);
                    const disabled = !questionIndicators.some(question => templateIndicatorIds.includes(question.id));
                    template.disabled = disabled;
                    if(disabled) template.tooltip = `Je project bevat geen vragen die bij dit formulier horen.<br>Beheer je project vragen via 'Beheer en toegang'`

                    return template
                })

                this.formTemplates = templatesWithDisabled;

                if(formTemplates.length > 0) this.initializedFormTemplates = true;
            } catch(error) {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formulieren' })
            }
        },
        getProjectQuestions() {
            // this will be a network call once projectindicators are saved somehwere else
            return this.project.references.components
                .filter(component => component.indicator.type === 'question')
                .map(component => component.indicator)
        },
        async onPdUsesCrowFlowChanged({ useCrowFlow }) {
            this.project.usesCrowFlow = useCrowFlow;
            this.project.hasChosenCrowFlow = true;
            if(useCrowFlow) this.project.private = false;
            this.getProjectComponents();
            this.updateShared();
            this.setProjectStatus();
        },
        getProjectJwt() {
            const project = JSON.parse(localStorage.getItem('projectJwt'));
            if(!project || project.id !== this.$route.params.id) return null
            return project.jwt
        },
        setJwt(jwt) {
            const projectJwt = {
                id: this.$route.params.id,
                jwt
            }
            localStorage.setItem('projectJwt', JSON.stringify(projectJwt))

            this.projectJwt = jwt;
        },
        resetJwt() {
            localStorage.removeItem('projectJwt');
        },
        async getProjectForms() {
            try {
                const projectJwt = this.getProjectJwt()
                const project = await this.$apollo.query({
                    query: GET_PROJECT_FORMS,
                    variables: {
                        id: this.$route.params.id,
                        projectJwt
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(response => response.data.project);

                this.forms = project.forms;
                this.editingFormTimeline = false;
                this.project.settings = project.settings;

                const organisationId = this.$store.getters.getCurrentOrganisation.id;
                const isGuestOrganisation = 
                    project.client &&
                    project.contractor &&
                    organisationId !== project.client.id &&
                    organisationId !== project.contractor.id
                
                this.project.forms = project.forms.filter((form) => {
                    if (isGuestOrganisation) return form.organisationId === project.client.id; 
                    else return true
                });
                this.initializedForms = true;

                return
            } catch(error) {
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formulieren' })
                return
            }
        },
        getProject() {
            this.$apollo.query({
                query: ACCESS_PROJECT,
                variables: {
                    id: this.$route.params.id
                },
                fetchPolicy: 'no-cache'
            })
            .then(response => {
                this.setJwt(response.data.project_access.jwt)
                if(!this.initializedForms) this.getProjectForms();

                const project = response.data.project_access.project;

                this.creatingForms = false;
                if (response.error) {
                    this.$store.commit('notify', {
                        type: 'warning',
                        message: `U heeft geen toegang tot dit project.`,
                    });
                    this.$router.go(-1);
                    return;
                }

                /**
                 * A project without references is a BROKEN project. Generally happens because a subscription has not run yet.
                 * Keep refreshing untill the references are found.
                 */
                if (!project || !project.references) {
                    console.log('%cno references found in project, refreshing...', 'color: aqua');
                    setTimeout(() => {
                        this.reload();
                    }, 1000);
                    return;
                }

                this.project = this.setProject(project);

                this.updateReferenceConversion();

                if(!this.initializedFormTemplates) this.setFormTemplates();

                this.updateShared();
                this.updateUserRoles();

                if (this.projectAbility.isGeust() && !this.isCrowMasterOrganisation) this.project = this.setSummaryProject(this.project);

                this.projectLoading = false;
            })
            .catch(error => {
                console.log(error)
                if (error) {
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: `Er ging iets fout tijdens het ophalen van dit project`,
                    });
                    this.$router.push('/projects');
                    return;
                }
            })
        },
        setProjectStatus() {
            this.$apollo.query({
                query: GET_PROJECT_STATUS,
                variables: {
                    id: this.$route.params.id,
                    projectJwt: this.getProjectJwt()
                },
                fetchPolicy: 'no-cache'
            })
            .then(response => {
                const project = response.data.project;
                this.project.contractorStatus = project.contractorStatus;
                this.project.clientStatus = project.clientStatus;
                this.project.status = this.organisationType === 'client' ? project.clientStatus : project.contractorStatus;
            })
        },
        getProjectComponents() {
            const projectJwt = this.getProjectJwt()
            this.$apollo.query({
                query: GET_PROJECT_REFERENCES,
                variables: {
                    id: this.project.id,
                    projectJwt
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const references = result.data.project.references;
                references.components = sortComponents(references.components);
                this.project.references = references;
                this.project = this.setProject({ ...this.project });
                if(!this.initializedFormTemplates) this.setFormTemplates();
                this.updateReferenceConversion();
            })
            .catch(error => {
                console.log(error)
            })
        },
        handleIndicatorRemoved(indicator) {
            const index = this.project.references.components.findIndex(component => component.indicatorId === indicator.id);
            if(index === -1) return
            this.project.references.components[index].disabled = true;
            this.project = this.setProject({ ...this.project });
            this.updateReferenceConversion();
        },
        setDuplicatingState(isDuplicating) {
            this.isDuplicating = isDuplicating;
            if(!isDuplicating) this.updateReferenceConversion();
        },
        handleSearchInput: _.debounce(function(event) {
            this.search = event.target.value || '';
        }, 200),
        indicatorMasterIdCheck(masterIds, indicator) {
            return masterIds.includes(indicator.masterId) || masterIds.includes(indicator.id)
        }
    },
    computed: {
        showTimeline: function() {
            if(!this.initializedForms || this.creatingForms) return false
            if(this.canViewTemplate && (!this.project.templates || !this.project.templates.length)) return false
            
            if(this.showUseBPQuestion) return true;
            
            if(!this.project.usesCrowFlow && this.initializedForms) return true
            
            if(this.isInitiator && this.project.usesCrowFlow && (!this.projectDetailsContext ||!this.hasAnsweredRequiredBPIndicators)) return false
            
            if(this.organisationType === 'contractor' && this.hasQfactPD && this.initializedForms) return true

            return this.crowProjectIsReady && !this.hasToInviteContactperson && this.initializedForms;
        },
        showUseBPQuestion: function() {
            if(this.project.hasChosenCrowFlow) return false

            const canUseCrow = this.$store?.getters.hasActiveQfactProducts;

            return this.isInitiator && canUseCrow
        },
        hasAnsweredRequiredBPIndicators: function() {
            const indicators = this.project.indicators.filter(indicator => this.indicatorMasterIdCheck(this.bpMasterIds, indicator));
            return indicators.filter(indicator => isValidAnswer(indicator.answer)).length === this.bpMasterIds.length
        },
        hasAnsweredRequiredIndicators: function() {
            let answered = false;

            const projectIndicators = this.project.indicators.filter(indicator => this.indicatorMasterIdCheck(this.defaultMasterIds, indicator) || this.crowMasterIds.includes(indicator.masterId));
            const projectIndicatorsAnswered = projectIndicators.filter(indicator => isValidAnswer(indicator.answer));
            answered = projectIndicators.length === projectIndicatorsAnswered.length;
            if(this.organisationType === 'contractor' && this.hasQfactPD && this.project.templates?.length === 0) answered = false;

            if(this.organisationType === 'client') return answered

            const required = this.project.indicators.filter(indicator => indicator.required);
            const requiredAnswered = required.filter(indicator => indicator.required && isValidAnswer(indicator.answer));
            if(answered) answered = required.length === requiredAnswered.length;
            
            return answered
        },
        hasContactpersonInCrowMeta: function() {
            if (this.project.crowMeta && this.project.crowMeta.contactpersonEmail) return true;
            else return false;
        },
        hasToInviteContactperson: function () {
            const { members, contractor, isMigrated, status } = this.project;

            if (!isMigrated || status === 'finished') return false;

            const { id: contractorId } = contractor;

            const contractorHasMembers = members.find((member) => member.organisationId === contractorId);
            const hasToInviteContactperson = contractorHasMembers === undefined;

            return hasToInviteContactperson;
        },
        canEditMembers: function () {
            return (
                this.projectAbility.can('admin__Project__add_member') ||
                this.projectAbility.can('admin__Project__change_member') ||
                this.projectAbility.can('admin__Project__remove_member')
            );
        },
        canEditProjectSettings: function () {
            if(this.projectLoading) return false
            return this.projectAbility.can('admin__Project__delete') || this.projectAbility.can('admin__Form__delete');
        },
        zeroFormsText: function() {
            return 'Er zijn nog geen formulieren beschikbaar in dit project.'
        },
        crowProjectIsReady: function () {
            let { formIds = [], forms } = this.project;
            const missingFormIds = forms.map(form => form.id).filter(id => formIds.indexOf(id) === -1);
            if (missingFormIds.length > 0 && this.discussedPsuReference?.answer) {
                this.repairingFormIds = true;
                this.$apollo
                    .mutate({
                        mutation: PROJECT_REPAIR_FORMIDS,
                        variables: { id: this.project.id },
                    })
                    .then(async (response) => {
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        this.repairingFormIds = false;
                        this.reload();
                    })
                    .catch((err) => {
                        this.repairingFormIds = false;
                        this.reload();
                    });
                return false;
            }
            return forms && forms.filter((form) => form.template && form.template.type === 'form').length > 0;
        },
        discussedPsuReference: function () {
            const discussedPsuIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'discussed-psu');

            const discussedPsu = this.project.indicators[discussedPsuIndex];

            if (!discussedPsu)
                return {
                    answer: null
                };

            return {
                ...discussedPsu,
                acceptedLabel: 'Better Performance en verwachtingen zijn besproken in startgesprek',
                rejectedLabel: 'Better Performance en verwachtingen zijn niet besproken in startgesprek',
                acceptButtonLabel: 'Ja, besproken',
                rejectButtonLabel: 'Nee, niet besproken'
            };
        },
        useBPReference: function() {
            if((!this.isInitiator && !this.project.hasChosenCrowFlow) ||
                (!this.project.usesCrowFlow && !this.$store.getters.hasActiveQfactProducts)) return null

            let acceptedLabel = 'Better Performance toepassen in dit project';
            if(!this.isInitiator && !this.hasAnsweredRequiredBPIndicators) acceptedLabel = 'Better Performance gaat toegepast worden in dit project'

            return {
                id: 'bp-reference',
                name: 'Let op! Als je aangeeft Better Performance toe te willen passen binnen je project, dan ben je verplicht om de formulieren in te vullen.',
                answer: this.project.usesCrowFlow,
                acceptedLabel: acceptedLabel,
                rejectedLabel: 'Geen Better Performance toepassen in dit project',
                acceptButtonLabel: 'Ja, toepassen',
                rejectButtonLabel: 'Nee, niet toepassen',
                protectionLevel: 'private',
                disabled: this.loadingComponentId === 'component-client'
            }
        },
        clientReference: function() {
            if (!this.defaultContext) return false;

            const clientIndex = this.defaultContext.indicators.findIndex(indicator => indicator.masterId === 'client');

            const client = this.defaultContext.indicators[clientIndex];

            if (!client)
                return {
                    answer: null,
                };

            return client;
        },
        projectTypeReference: function () {
            const projectTypeIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'crow-type');

            const projectType = this.project.indicators[projectTypeIndex];

            if (!projectType)
                return {
                    answer: null,
                };

            return projectType;
        },
        formVersionReference: function () {
            const formVersionIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'form-version');

            const formVersion = this.project.indicators[formVersionIndex];

            if (!formVersion)
                return {
                    answer: null,
                    notInProject: true
                };

            return formVersion;
        },
        durabilityReference: function () {
            const durabilityIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'durability');

            const durability = this.project.indicators[durabilityIndex];

            if (!durability)
                return {
                    answer: null,
                    notInProject: true
                };

            return durability;
        },
        contractorReference: function () {
            if (!this.defaultContext) return false;

            const contractorIndex = this.defaultContext.indicators.findIndex(indicator => indicator.masterId === 'contractor');

            const contractor = this.defaultContext.indicators[contractorIndex];

            if (!contractor)
                return {
                    answer: null,
                };

            return contractor;
        },
        endDateReference: function () {
            if (!this.defaultContext) return false;

            const endDateIndex = this.defaultContext.indicators.findIndex(indicator => indicator.masterId === 'project-end-date');

            const endDate = this.defaultContext.indicators[endDateIndex];

            if (!endDate)
                return {
                    answer: null,
                };

            return endDate;
        },
        invoicedSumReference: function () {
            const invoicedSumIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'invoiced-sum');

            const invoicedSum = this.project.indicators[invoicedSumIndex];

            if (!invoicedSum)
                return {
                    answer: null,
                };

            return invoicedSum;
        },
        collaborationContractorReference: function () {
            const collaborationContractorIndex = this.project.indicators.findIndex(indicator => indicator.masterId === 'collaboration-contractor');

            const collaborationContractor = this.project.indicators[collaborationContractorIndex];

            if (!collaborationContractor)
                return {
                    answer: null,
                };

            return collaborationContractor;
        },
        amsterdamPricingReference: function() {

            const collaborationContractorIndex = this.project.indicators.findIndex(indicator => indicator.id === 'amsterdam-pricing');

            const collaborationContractor = this.project.indicators[collaborationContractorIndex];

            if (!collaborationContractor)
                return {
                    answer: null,
                };

            return collaborationContractor;
        },
        amsterdamPricingRelevant: function() {
            return this.project.references.components.find(component => component.id === 'component-amsterdam-pricing')
        },
        defaultContext: function () {
            const defaultIndicators = this.project.indicators.filter((indicator) => indicator.context?.default === true && !indicator.display);

            if (defaultIndicators.length === 0) return null;

            return {
                name: defaultIndicators[0].context.name,
                indicators: defaultIndicators,
            };
        },
        projectDetailsContext: function () {
            const projectDetailsIndicators = this.project.indicators.filter((indicator) => indicator.context.id === 'crow-project-details' && !indicator.display);

            if (projectDetailsIndicators.length === 0) return null;

            return {
                name: projectDetailsIndicators[0].context.name,
                indicators: projectDetailsIndicators,
            };
        },
        options: function () {
            let categories = this.project.settings.categories;

            categories.forEach((category) => {
                const forms = this.project.forms.filter(
                    (form) => category.formIds && category.formIds.includes(form.id)
                );

                category.forms = forms;
                category.referenceLetter = { id: category.referenceLetterId };
            });

            return {
                categories,
            };
        },
        canViewTemplate: function() {
            return this.organisationType === 'contractor' && this.hasQfactPD && !this.project.pdHybridmode
        },
        hasQfactPD: function() {
            return this.$store?.getters.hasQfactPD
        },
        hasPDFullProduct: function() {
            return this.$store?.getters.hasPDFullProduct
        },
        isInitiator: function() {
            return this.$store?.getters.getCurrentOrganisation.id === this.project.organisationId
        },
        organisationType: function() {
            return this.$store?.getters.getOrganisationType
        },
        requiredIndicatorsCombination: function() {
            const defaultRequired = ['client', 'contractor','project-start-date','project-end-date','budget'];
            const crowProjectRequired = ['client', 'contractor', 'crow-type', 'form-version', 'crow-project-type', 'crow-contractform', 'crow-procedure', 'crow-assignment-criteria'];

            if(this.project.usesCrowFlow) return crowProjectRequired
            else return defaultRequired
        },
        requiredCrowIndicatorsCombination: function() {
            const projectIndicatorMasterIds = this.project.indicators.map(indicator => indicator.masterId);

            const requiredIndicators = ['client', 'contractor', 'crow-type'];
            const optionalRequiredIndicators =  ['durability', 'form-version'].filter(masterId => projectIndicatorMasterIds.includes(masterId));
            
            const requiredIndicatorMasterIds = [ ...requiredIndicators, ...optionalRequiredIndicators ];
            
            return requiredIndicatorMasterIds
            
        },
        projectName: function() {
            if(this.isInitiator) return this.project.name
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}ProjectName`;
            const projectName = this.project[key];
            return projectName
        },
        subProjectName: function() {
            if(this.isInitiator) return null
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}ProjectName`;
            const projectName = this.project[key];
            if(this.project.name !== projectName) return this.project.name
            return null
        },
        // these masterIds are the default indicators that are present in this project
        defaultMasterIds: function() {
            const indicators = this.project.indicators.filter(indicator => indicator.context.id === 'default-context' && !indicator.display);
            const masterIds = indicators.map(indicator => indicator.masterId);

            return masterIds
        },
        // these masterIds are the crow indicators that are present in this project
        crowMasterIds: function() {
            const indicators = this.project.indicators.filter(indicator => indicator.context.id === 'crow-project-details' && !indicator.display);
            const masterIds = indicators.map(indicator => indicator.masterId);

            return masterIds
        },
        // these masterIds are required to create the bp forms
        bpMasterIds: function() {
            const requiredMasterIds = ['client', 'contractor', 'crow-type'];
            const dynamicRequiredMasterIds = ['durability', 'form-version', 'amsterdam-pricing'];

            let masterIdsInProject = requiredMasterIds;
            dynamicRequiredMasterIds.forEach(masterId => {
                if(this.project.indicators.some(indicator => this.indicatorMasterIdCheck([masterId], indicator))) masterIdsInProject.push(masterId);
            });

            return masterIdsInProject
        },
        canAdminAgree: function() {
            const { users } = this.$store.getters.getCurrentOrganisation;
            
            if (!users) return {
                able: this.isCrowAdmin,
                reason: this.isCrowAdmin ? null : 'Een CROW Beheerder moet deze actie uitvoeren'
            }
            
            const activeUsers = users.filter(user => user.status === 'active');
            const adminUsers = activeUsers.filter(user => user.roleId === 'admin-default');
            const crowAdminUsers = activeUsers.filter(user => user.roleId === 'crow-admin-default');
            const jwtPayload = jwtDecode(this.projectJwt);

            const status = {
                able: true,
                reason: null
            }
            
            if (crowAdminUsers.length === 0 && adminUsers.length >= 1) {
                status.able = this.isAdmin;
                status.reason = this.isAdmin ? null : 'Een beheerder moet deze actie uitvoeren';
            }
            else {
                status.able = this.isCrowAdmin;
                status.reason = this.isCrowAdmin ? null : 'Een CROW Beheerder moet deze actie uitvoeren';
            }

            return status
        },
        isCrowAdmin: function () {
            return this.ability.get().can('admin', 'Crow');
        },
        isAdmin: function() {
            return this.$store.getters.getCurrentOrganisation.users.find(user => user?.userId === this.$store.getters.getUser.id)?.roleId === 'admin-default';
        },
    },
    async created() {
        this.getProject();
    },
    beforeDestroy() {
        this.projectAbility.remove();
    },
    watch: {
        '$route.path': function() {
            this.projectLoading = true;
            this.reload();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.select-templates-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.collaboration-contractor {
    margin: 10px 0 0 0;
    padding: 0 0 0 33px;

    .label {
        display: flex;
        align-items: center;
        gap: 6px;

        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }
    .answer {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
    }
}

.on-not-set {
    width: 530px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .loader-wrapper {
        display: flex;
        justify-content: center;
        padding: 18px 60px;
        margin-bottom: -4px !important;
    }
    .loader {
        $loader-color: $color-primary;
        $loader-size: 7px;
        $loader-height: 16px;
        $loader-border-size: 6px;
        $loader-gap: 18px;
        $loader-animation-duration: 1.2s;
        @import '../../../components/qds/assets/loaders/loaders.scss';
        @include loader12;
    }
}

.project-page {
    position: relative;
    min-height: calc(100vh - 180px);
}
.contexts-container {
    margin-top: 20px;
}

.margin-right {
    margin-right: -10px;
}

.project {
    display: flex;
    justify-content: space-between;

    .right {
        padding-right: 18px;
    }

    .left {
        margin-right: 30px;
    }
}

.panel {
    position: relative;
    padding: 24px 24px 16px 24px;
    width: 500px;

    &.default {
        position: unset !important;
        margin-bottom: 40px;
    }

    h4 {
        margin-bottom: 24px;
    }

    .buttons {
        position: absolute;
        top: 24px;
        right: 34px;
        display: flex;
        gap: 8px;
        background-color: #ffffff;
        padding-left: 8px;
    }
}

.options {
    width: 500px;
    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 14px;

        .title {
            line-height: 24px;
            margin: 0 16px 0 24px;
        }

        &.search {
            color: $color-grey-5;
            padding-right: 24px;
            transition: all 200ms ease;

            &:hover {
                color: $color-grey-7;
            }

            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
            }

            .input {
                width: 108px;
                border: none;
                outline: none;
                padding: 0;
                color: $color-grey-5;
                font-size: $size-s;

                &:focus {
                    color: $color-grey-7;
                }

                &::placeholder {
                    color: $color-grey-5;
                }
            }

            .not-searching {
                cursor: pointer;
            }
        }

        .icon {
            transform: translate(-3px, 2px);
        }
    }
}

.bolder {
    font-weight: 500 !important;
}

.head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        // align-items: center;

        .project-name {
            display: flex;
            flex-direction: column;

            h2 {
                line-height: 2.6rem;
            }

            .sub-name {
                margin-top: -4px;
                font-weight: 500;
                color: $color-grey-5;
            }
        }


        .status {
            padding-top: 8px;
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: -3px;
        }
    }

    .right {
        display: flex;
        align-items: flex-start;
        padding-top: 8px;

        .share {
            display: flex;
            align-items: center;

            .avatars {
                height: 30px;
            }
        }
    }
}

.invite-contactperson-button {
    display: flex;
    justify-content: center;

    margin: 5px 0 0 0;
}

* {
    font-family: Gotham;
}

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
        background-position: -150px;
    }

    100% {
        background-position: 350px;
    }
}

.loading-panel {
    padding: 24px 24px 16px 24px;
    width: 500px;
    margin-bottom: 40px;

    .blank-row:not(.title),
    .blank-title,
    .blank-badge {
        @include background-gradient;
        animation: shine-lines $animation-duration infinite ease-out;
        .skeleton-line ~ .skeleton-line {
            background-color: #ddd;
        }
    }

    h4 {
        margin-bottom: 24px;
        color: $color-grey-3;
    }

    .blank-row,
    .blank-title {
        background-color: lighten($color-grey-3, 5%);
        border-radius: 4px;
    }

    .blank-title {
        width: 100%;
        height: 24px;
        width: 60%;
        margin-bottom: 20px;
    }

    .blank-row {
        width: 50%;
        height: 20px;
        margin: 10px 0;
    }

    .blank-row:first-child {
        margin-right: 10%;
        width: 100px;
    }

    .blank-row:last-child {
        margin: 0;
    }

    .indicator {
        display: flex;
        align-items: center;
        // transition: 200ms;
        height: 35px;
        color: $color-grey-9;
        border-radius: 4px;
        margin: -4px -12px;
        padding: 4px 12px;

        &.selected {
            background-color: lighten($color-grey-3, 5%);
        }

        .name {
            flex: 0 0 35%;
        }
        .answer {
            flex: 0 0 55%;
        }

        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .answer {
            font-weight: normal;
            font-size: 13px;
            line-height: 22px;

            .default {
                display: flex;
                justify-content: space-between;

                .edit {
                    display: none;
                }
            }
        }
    }
}
</style>
