<template> 
    <div class="page-padding">
        <div v-if="loadingProjects || hasProjects">
            <div class="flex space-between mb-l">
                <div class="flex align-center">
                    <h2>Projecten</h2>
                    <q-icon-button class="ml-s" icon="SettingsWheel" @click="handleOpenMenu" :active="showMenu" animation="rotate" iconColor="#aeb5bc"></q-icon-button>
                </div>
                <div class="flex" style="gap:10px;">
                    <jump-transition>
                            <q-button   
                            class="export-button" variation="light"
                            icon="download"
                            v-if="!freemiumOrganisation && projectsMinimal.length"
                            @click="downloadExport"
                            >{{ exportButtonText }}
                        </q-button>
                    </jump-transition>                    
                    <q-button
                        v-if="canCreateNewProject"
                        v-can="'Project__create_crow'"
                        :iconStyle="iconStyle"
                        iconSize="22"
                        icon="helmet"
                        @click="$router.push('/projects/create')"
                        >Nieuw project
                    </q-button>                                                         
                </div>                
            </div>
            <q-table-with-pagination
                :data="formattedProjects"
                :columns="columns"
                :tableLoading="loadingProjects"
                :maxRows="paginatedTableLength"
                :dataLength="projectsMinimal.length"
                :isSearching="Boolean(selectedFiltersLength)"
                overflowX="auto"
                clickable
                ref="projectsTable"
                @click="select"
                @filterUpdated="handleFilterUpdated"
                @filterSearch="_filterSearchChanged"
                @pageSelection="handleTablePagination"
                @filterInitialized="handleFiltersInitialized"
                @tableInitialized="handleUpdateTable"
            >
                <template v-slot:row="{ row, column }">
                    <span v-if="column == 'norm'">
                        <img
                            v-if="row[column]"
                            class="crow-logo"
                            src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png"
                            alt="crow-logo"
                        />
                    </span>
                    <span v-else-if="column == 'status'" style="display:flex;justify-content:flex-end">
                        <q-tag
                            size="medium"
                            :variation="row.status !== 'finished' ? 'warning' : 'success'"
                        >{{ row[column] || 'Projectstatus' }}</q-tag>
                    </span>
                    <span v-else-if="column == 'members'">
                        <q-avatar-group :images="getMembersAvatars(row[column])" :max="2" />
                    </span>
                    <span v-else-if="column == 'name'" class="boldText ellipsis-2">
                        {{ row[column] }}
                    </span>
                    <span v-else-if="['client','contractor'].includes(column)" class="ellipsis-2">
                        {{ row[column] }}
                    </span>
                </template>
            </q-table-with-pagination>
        </div>

        <div v-else>
            <q-zerostate
                class="zerostate"
                img="/statics/img/project_gradient.svg"
                title="Projecten"
                :description="zerostateDescription"
            >
                <q-button v-if="canCreateNewProject" v-can="'Project__create_crow'" @click="createProject">Nieuw project maken</q-button>
            </q-zerostate>
        </div>
        
        <q-popup v-if="showContractorCrowProductPopup" @close="showContractorCrowProductPopup = false" showCloseButton>
            <div class="modal">
                <h2 class="pb-s">
                    CROW product<br />
                    niet geactiveerd
                </h2>
                <p>
                    Uw organisatie heeft niet het CROW product geactiveerd.<br />
                    Neem contact op met de helpdesk
                </p>
                <div class="footer flex-end"></div>
            </div>
        </q-popup>

        <project-configuration-popup :show="showMenu" @configurationSaved="handleConfigurationSaved" @configurationUpdated="handleConfigurationUpdated" @close="showMenu = false;"></project-configuration-popup>
    </div>
</template>

<script>
import _ from 'lodash';
import gql from 'graphql';
import ProjectConfigurationPopup from './ProjectConfigurationPopup.vue';

import { GET_PROJECTS, GET_PROJECTS_BY_READMODEL, GET_PROJECTS_BY_READMODEL_MINIMAL, ORGANISATIONS_MINIMAL, ORGANISATION_USERS, GET_INDICATORS, GET_VALUES } from '../../graphql/queries';
import { getDate, getMoney, extractError, canCreateNewProject, userInitials, getCalculatedRows, handleExportCsv, freemiumOrganisation } from '../../assets/js/utils';

export default {
    name: 'Projects',
    components: {
        ProjectConfigurationPopup
    },
    data() {
        return {
            loadingProjects: true,
            initialisedProjects: false,
            zerostateDescription: this.ability.get().can('create_crow', 'Project')
                ? 'Je hebt nog geen project aangemaakt, of je bent nog niet uitgenodigd om aan een project deel te nemen. Ga vandaag aan de slag met je eerste project!'
                : 'Er zijn nog geen project waar u lid van bent. Vraag een projectbeheerder om uitgenodigd te worden voor een project.',
            iconStyle: {
                marginLeft: '-10px',
                marginTop: '-2px'
            },
            projects: [],
            projectsFilterOptions: [],
            organisationFilterOptions: [],
            whereQuery: {},
            sorting: ['creationDate__DESC'],
            hasProjects: false,
            showContractorCrowProductPopup: false,
            paginatedTableLength: 10,
            paginatedProjects: [],
            projectsMinimal: [],
            allMinimalProjects: [],
            membersFilterOptions: [],
            statusFilterOptions: [],
            selectedFiltersLength: 0,
            projectsOrganisationIds: [],
            initializedTableLength: false,
            activeTableIndex: 0,
            showMenu: false,
            hover: false,
            columns: [],
            columnsToResolve: [],
            columnsFilterData: {},
            exportingProjects: false,
            exportedProjects: []
        };
    },
    methods: {
        findByKey(object, key) {
            if(!key.includes('.')) return object[key];
            const keys = key.split('.');
            let value = object;
            keys.forEach(keyStep => value = value[keyStep]);
            return value
        },
        select(row) {
            if (row.status === 'finished' && !this.canViewFinishedProjects) this.showContractorCrowProductPopup = true;
        },
        createProject() {
            this.$router.push('/projects/create');
        },
        get() {
            const first = this.paginatedTableLength;
            const skip = this.paginatedTableLength * this.activeTableIndex;
            const fetchPolicy = this.getFetchPolicy();

            this.loadingProjects = true;

            this.$apollo
                .query({
                    query: GET_PROJECTS_BY_READMODEL,
                    variables: {
                        where: this.whereQuery,
                        sort: this.sorting,
                        first: first,
                        skip: skip
                    },
                    fetchPolicy
                })
                .then(response => {  
                    this.paginatedProjects = this.transformColumns(response.data.projectReadmodels);
                    if(!this.hasProjects) this.hasProjects = this.paginatedProjects.length > 0;
                    this.loadingProjects = false;
                })
                .catch(error => {
                    (this.loadingProjects = false), this.$store.commit('notify', extractError(error));
                });
        },
        getIndicatorDisplayValue(indicator = {}, value) {
            let { answerType, displayType, answerValues = [], unit, masterId } = indicator;

            if(['checkbox','dropdown-multiple'].includes(answerType)) {
                console.log(value)
                const selectedAnswerValues = value.map(responseValue => answerValues.find(answerValue => answerValue.value === responseValue));
                return selectedAnswerValues.map(answerValue => answerValue.label).join(', ');
            }
            if(['radio','dropdown','organisation'].includes(answerType)) {
                return answerValues.find(answerValue => answerValue.value === value).label;
            }
            if(answerType === 'date') {
                return getDate(value);
            }
            if(answerType === 'upload') {
                return value.documentName;
            }
            if(displayType === 'currency') {
                return getMoney(value);
            }
            if(displayType === 'numberUnit') {
                return `${value} ${unit}`;
            }
            return value;
        },
        getCustomDisplayValue(column, project) {
            if(column.key.includes('ProjectName')) {
                return this.getProjectName(project.info);
            }
            if(column.key.includes('creationDate')) {
                return getDate(project.info.creationDate);
            }
            if(column.key.includes('Status')) {
                const status = this.findByKey(project, column.key) || project.info.status;
                return status ? this.$t(`projectStatus.${status}`) : '';
            }
            if(column.key.includes('members')) {
                const members = this.findByKey(project, column.key) || [];
                const activeMembers = members.filter(user => user.status === 'active');
                return activeMembers.map(member => member.user);
            }
            return this.findByKey(project, column.key) || '';
        },
        setFilters(filter) {
            if(Object.keys(filter).length) {
                const filters = Object.keys(filter).filter(filterName => {
                    if(typeof filter[filterName].filter === 'array' && filter[filterName].filter.length > 0) return true
                    if(typeof filter[filterName].filter === 'object' && Object.keys(filter[filterName].filter).length > 0) return true
                });
                this.selectedFiltersLength = filters.length;
                try {
                    this.$refs.projectsTable.resetCounter();
                } catch(error) {}
            }

            let query = {};
            const { id: userId } = this.$store.getters.getUser;

            if (this.$route.name === 'ProjectsMine') {
                query = {
                    info_members__some: {
                        userId
                    }
                };
            }

                
            const whereQuery = {
                AND: [
                    query
                ]
            };
            if(this.organisationType === 'client') whereQuery.AND.push( { info_private__in: [false, null] });
                
            const configuration = this.$store.getters.getProjectConfiguration;
            const statusKey = `${this.organisationType}Status__in`;
            Object.keys(filter).forEach(key => {
                const column = configuration.columns.find(column => column.id === key);
                const { filterType } = this.columns.find(column => column.field === key) || {};
                let hasActiveFilters = false;
                
                if(filterType === 'checkbox') hasActiveFilters = filter[key].filter.length > 0;
                if(['number','currency'].includes(filterType)) hasActiveFilters = filter[key].filter.length === 2;
                if(filterType === 'datePicker') hasActiveFilters = !isNaN(filter[key].filter.from) && !isNaN(filter[key].filter.to);

                if(!hasActiveFilters) return;

                if(column.type === 'custom') {
                    let query = {};
                    switch (key) {
                        case 'name':
                            query = {
                                projectId__in: filter[key].filter
                            };
                            break;
                        case 'creationDate':
                            query = {
                                AND: [
                                    { info_creationDate__gte: filter[key].filter.from },
                                    { info_creationDate__lte: filter[key].filter.to }
                                ]
                            };
                            break;
                        case 'contractor':
                            query = {
                                contractorId__in: filter[key].filter
                            };
                            break;
                        case 'client':
                            query = {
                                clientId__in: filter[key].filter
                            };
                            break;
                        case 'members':
                            query = {
                                info_members_userId__in: filter[key].filter
                            }
                            break;
                        case 'status':
                            query = {
                                [`info_${statusKey}`]: filter[key].filter
                            }
                            break;
                    }
                    whereQuery.AND.push(query);
                } else {
                    let valueQuery = {};
                    let value = filter[key].filter;
                    if(filterType === 'checkbox') {
                        // booleans worden vervangen naar strings
                        valueQuery = {
                            value__in: value
                        };
                    } else if(['number','score','currency'].includes(filterType)) {
                        valueQuery = {
                            AND: [
                                { value__gte: filter[key].filter[0] },
                                { value__lte: filter[key].filter[1] }
                            ]
                        };
                    } else if(filterType === 'datePicker') {
                        valueQuery = {
                            AND: [
                                { value__gte: filter[key].filter.from },
                                { value__lte: filter[key].filter.to }
                            ]
                        };
                    }
                    whereQuery.AND.push({ 
                        forms__some: {
                            components__some: {
                                AND: [
                                    {
                                        OR: [
                                            { indicatorId: column.key },
                                            { masterIndicatorId: column.key }
                                        ]
                                    },
                                    {
                                        responses__some: valueQuery
                                    }
                                ]
                            }
                        }
                    });
                }
            });

            this.whereQuery = whereQuery;

            let sorting = [];

            this.sorting = Object.keys(filter)
                .filter(field => filter[field].latest)
                .map(field => {
                    const sort = this.getSortingCondition(field, filter[field].sorting);
                    sorting.push(sort);
                });

            if(Object.keys(filter).length === 0) {
                const defaultSortColumn = this.columns.find(column => column.defaultSort);
                if(defaultSortColumn) {
                    const sort = this.getSortingCondition(defaultSortColumn.field, defaultSortColumn.defaultSort);
                    sorting.push(sort);
                }
            }

            this.sorting = sorting;
        },
        getSortingCondition(field, sorting) {
            const { columns } = this.$store.getters.getProjectConfiguration;
            const key = columns.find(column => column.id == field).key;

            switch (field) {
                case 'status':
                    return `info_${this.organisationType}Status__${sorting}`
                default:
                    return `${key}__${sorting}`
            }
        },
        handleUpdateTable() {
            if(!this.initializedTableLength) return

            this.get();
            this.getProjectsMinimal();
        },
        handleFilterUpdated(filter) {
            this.setFilters(filter);
            this.handleUpdateTable();
        },
        handleFiltersInitialized(filter) {
            this.setFilters(filter);
            this.getProjectsMinimal();
        },
        _filterSearchChanged: _.debounce(function(data) {
            return this.filterSearchChanged(data);
        }, 500),
        filterSearchChanged({ column, filterData }) {
            switch (column) {
                case 'name':
                    this.getProjectFilterOptions(filterData);
                    break;
                case 'contractor':
                case 'client':
                    this.getOrganisationFilterOptions(filterData.search);
                    break;
                case 'members':
                    this.getMembersFilterOptions(filterData.search);
                    break;
                case 'status':
                    this.getStatusFilterOptions(filterData.search);
                    break;
                default:
                    this.handleSearchFilterOptions(column, filterData.search);
                    break;
            }
        },
        handleSearchFilterOptions(columnField, search) {
            const column = this.columns.find(column => column.field === columnField);
            let filterOptions = column.filterOptions || [];
            if(!search) {
                filterOptions = filterOptions.map(option => {
                    delete option.hidden
                    return option
                });
            } else {
                filterOptions = filterOptions.map(option => {
                    const show = option.label.toLowerCase().includes(search.toLowerCase());
                    option.hidden = !show;
                    return option
                });
            }
            this.fillColumnsFilterData(column.key, 'filterOptions', filterOptions);
        },
        getProjectFilterOptions(filterData = {}) {
            this.fillColumnsFilterData('name', 'loading', true);
            const search = filterData.search || '';

            const selectedProjectIds = filterData.filter || [];

            const where = {
                AND: [
                    {
                        OR: [
                            { info_name__contains: search },
                            { projectId__in: selectedProjectIds }
                        ]
                    },
                    
                ]
            };
            if(this.organisationType === 'client') where.AND.push({ info_private__in: [false, null] });

            this.$apollo
                .query({
                    query: GET_PROJECTS_BY_READMODEL_MINIMAL,
                    variables: {
                        where,
                        caseSensitive: false
                    },
                    fetchPolicy: 'network-only'
                })
                .then(result => {
                    const filterOptions = result.data.projectReadmodels.map(project => {
                        return {
                            value: project.projectId,
                            label: this.getProjectName(project.info),
                            hidden: selectedProjectIds.includes(project.projectId)
                        };
                    });
                    this.fillColumnsFilterData('name', 'filterOptions', filterOptions);
                })
                .catch(error => {
                    this.fillColumnsFilterData('name', 'loading', false);
                });
        },
        async getOrganisationFilterOptions(search) {
            const slug = this.organisationType === 'client' ? 'contractor' : 'client';
            this.fillColumnsFilterData(slug, 'loading', true);

            this.$apollo
                .query({
                    query: ORGANISATIONS_MINIMAL,
                    variables: {
                        where: {
                            AND: [
                                {
                                    name__contains: search
                                },
                                {
                                    type: 'main'
                                },
                                {
                                    id__in: this.projectsOrganisationIds
                                }
                            ]
                        },
                        first: 20,
                        sort: ['name__DESC'],
                        caseSensitive: false
                    },
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    const filterOptions = result.data.organisations.map(organisation => {
                        return {
                            value: organisation.id,
                            label: organisation.name
                        };
                    });
                    this.fillColumnsFilterData(slug, 'filterOptions', filterOptions);
                })
                .catch(error => {
                    this.fillColumnsFilterData(slug, 'loading', false);
                });
        },
        getStatusFilterOptions(search = '') {
            this.fillColumnsFilterData('status', 'loading', true);
            if(search === '') return this.setAllProjectStatusOptions()

            const statusses = this.allMinimalProjects.map(project => {
                return {
                    value: project.status,
                    label: project.statusLabel
                };
            });
            let mappedStatusses = statusses.map(status => status.value);
            let filteredDuplicates = statusses.filter((status, index) => mappedStatusses.indexOf(status.value) == index && status.label.toLowerCase().includes(search.toLowerCase()));
            const filterOptions = filteredDuplicates;
            this.fillColumnsFilterData('status', 'filterOptions', filterOptions);
        },
        getMembersFilterOptions(searchQuery) {
            this.fillColumnsFilterData('members', 'loading', true);
            let { users } = this.$store.getters.getCurrentOrganisation;

            if (searchQuery) {
                users = users.filter(user => 
                    user.user.firstName.includes(searchQuery) || 
                    user.user.lastName.includes(searchQuery) || 
                    user.user.email.includes(searchQuery))
            }

            users = users.filter(user => user.user && (user.user.firstName || user.user.lastName));
            
            const filterOptions = users.map(user => {
                return {
                    value: user.userId,
                    label: `${user.user.firstName} ${user.user.lastName}`
                }
            });
            this.fillColumnsFilterData('members', 'filterOptions', filterOptions);
        },
        handleTablePagination(selectedPageIndex) {
            this.activeTableIndex = selectedPageIndex - 1;
            if(!this.initializedTableLength) return

            this.get();
        },
        getProjectsMinimal(initialize = false) {
            this.$apollo.query({
                query: GET_PROJECTS_BY_READMODEL_MINIMAL,
                variables: {
                    where: initialize ? {} : this.whereQuery
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                let projects = result.data.projectReadmodels.map(readmodel => {
                    return {
                        id: readmodel.projectId,
                        ...readmodel.info
                    }
                });
                if(!this.hasProjects) this.hasProjects = projects.length > 0;

                const projectsOrganisationIds = [];
                const oppositeType = this.organisationType === 'contractor' ? 'client' : 'contractor'
                projects.forEach(project => {
                    const id = project[oppositeType+'Id'];
                    if(!id || projectsOrganisationIds.includes(id)) return
                    projectsOrganisationIds.push(id)
                });
                this.projectsOrganisationIds = projectsOrganisationIds;

                projects = projects.map(project => {
                    const status = project[`${this.organisationType}Status`] || project.status;
                    project.status = status;
                    project.statusLabel = this.$t(`projectStatus.${status}`);
                    return project
                });
                
                if(initialize) {
                    this.allMinimalProjects = projects;
                    if(this.projectsMinimal.length === 0) this.projectsMinimal = projects;

                    this.getOrganisationFilterOptions();
                    this.setAllProjectStatusOptions();
                    this.getStatusFilterOptions();
                    this.getMembersFilterOptions();
                    this.getIndicatorMaxValues();
                }
                else this.projectsMinimal = projects;
            })
            .catch(error => console.log(error))
        },
        getMembersAvatars(members = []) {
            return members.map(member => {
                return {
                    image: member.avatar,
                    fallback: userInitials(member.firstName, member.lastName),
                    tooltip: `${member.firstName} ${member.lastName}`
                }
            });
        },
        getProjectName(project) {
            const key = `${this.organisationType}ProjectName`;
            const projectName = project[key];
            return projectName || project.name
        },
        setAllProjectFilterOptions() {
            this.projectsFilterOptions = this.allMinimalProjects.map(project => {
                return {
                    label: this.getProjectName(project),
                    value: project.id
                }
            })
        },
        setAllProjectStatusOptions() {
            const statusses = this.allMinimalProjects.map(project => {
                return {
                    value: project.status,
                    label: project.statusLabel
                };
            });
            let mappedStatusses = statusses.map(status => status.value);
            let filteredDuplicates = statusses.filter((status, index) => mappedStatusses.indexOf(status.value) == index);
            const filterOptions = filteredDuplicates;
            this.fillColumnsFilterData('status', 'filterOptions', filterOptions);
        },
        initializeFilterData() {
            this.getProjectsMinimal(true);
            this.getProjectFilterOptions();
            this.getIndicatorColumnsFilterOptions();
        },
        getFetchPolicy() {
            const routerEvent = this.$store.getters.getLastRoutingEvent;
            if(Object.keys(routerEvent).length === 0) return 'no-cache'

            const from = this.removeQueryParams(routerEvent.from);
            const to = this.removeQueryParams(routerEvent.to);
            return from === to ? 'cache' : 'no-cache'
        },
        removeQueryParams(url) {
            if(!url.includes('?')) return url
            return url.split('?')[0]
        },
        transformColumns(projects) {
            const configuration = this.$store.getters.getProjectConfiguration;
            const columns = configuration.columns || [];

            return projects.map(project => {
                const forms = project.forms || [];
                const referencesForm = forms[0] || {};
                referencesForm.components = referencesForm.components || [];
                const members = project.info?.members || [];
                const activeMembers = members.filter(user => user.status === 'active');

                const object = {
                    to: project.status === 'finished' && !this.canViewFinishedProjects ? '' : `/projects/${project.projectId}?title=${project.info.name}`
                }

                columns.forEach(column => {
                    let displayValue = '-';
                    let answerValues = [];
                    const field = column.id;
                    if(['client','contractor'].includes(column.key)) {
                        if(project.project[column.key]) displayValue = project.project[column.key].name;
                    }
                    else if(column.key.includes('status')) {
                        displayValue = this.getCustomDisplayValue(column, project) || project.info.status;
                    }
                    else if(column.type === 'indicator') {
                        const indicatorId = column.key;
                        const masterId = column.indicator?.masterId;

                        if(masterId === 'contractor-branch') {
                            const branches = this.$store.getters.getCurrentOrganisation.childs;
                            column.indicator.answerValues = branches.map(branch => {
                                return {
                                    label: `${branch.address.zip}, ${branch.address.city}`,
                                    value: branch.id
                                }
                            })
                        }
                        
                        const component = referencesForm.components.find(component => component.indicatorId === indicatorId || (masterId && component.masterIndicatorId === masterId));
                        if(!component) return object[field] = displayValue;

                        const componentResponse = component.responses[0];
                        if(!componentResponse) return object[field] = displayValue;

                        displayValue = this.getIndicatorDisplayValue(column.indicator, componentResponse.value);
                    } else {
                        displayValue = this.getCustomDisplayValue(column, project);
                    }

                    object[field] = displayValue;
                });

                object['memberString'] = activeMembers
                    .filter(member => member)
                    .map(member => `${member.user.firstName} ${member.user.lastName}`).join(', ');

                return object
            })
        },
        async getProjectBatch(first, skip) {
            for (let i = 0; i <= 3; i++) {
                await new Promise(r => setTimeout(r, 400*i*i))
                try {
                    const projectBatch = await this.$apollo.query({
                        query: GET_PROJECTS_BY_READMODEL,
                        variables: {
                            where: this.whereQuery,
                            sort: this.sorting,
                            first,
                            skip
                        },
                        fetchPolicy: 'no-cache'
                    });
                    return Array.isArray(projectBatch.data.projectReadmodels) ? projectBatch.data.projectReadmodels : null;
                } catch {}
            }
            return null;                
        },
        async downloadExport() {
            this.exportingProjects = true;
            this.exportedProjects = [];
            const first = 10;
            const projectCount = this.projectsMinimal.length;
            for (let skip = 0; skip < projectCount; skip += first) {                
                const projectBatch = await this.getProjectBatch(first, skip);
                if (projectBatch === null) {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de projecten' })
                    this.exportingProjects = false;
                    return;
                }
                this.exportedProjects = [ ...this.exportedProjects, ...this.transformColumns(projectBatch)];                
            }
            const columns = this.columns.filter(column => column.field !== 'norm').map(column => { 
                if (column.field === 'members') return { ...column, field: 'memberString' };
                return column;
            });
            const fileName = this.selectedFiltersLength ? 'Projecten (gefilterd).csv' : 'Projecten.csv';
            handleExportCsv(this.exportedProjects, columns, fileName);
            this.exportingProjects = false;
        },
        handleOpenMenu() {
            this.showMenu = true;
        },
        handleConfigurationUpdated() {
            this.setColumns();
        },
        handleConfigurationSaved() {
            this.showMenu = false;
            this.handleUpdateTable();
            this.initializeFilterData();
        },
        getColumnFilterType(column) {
            const { id } = column;
            const { answerType, displayType } = column.info;
            if(['checkbox','radio','dropdown','dropdown-multiple','organisation','user'].includes(answerType) || ['name','status'].includes(id)) return 'checkbox'
            if(['score'].includes(answerType)) return 'score'
            if(displayType === 'currency') return 'currency'
            if(['number','numberUnit'].includes(answerType)) return 'number'
            if(['date'].includes(answerType)) return 'datePicker'
            return null
        },
        getColumnFilterOptions(column) {
            const { answerType, displayType, masterId } = column.indicator || {};

            let filterOptions = this.columnsFilterData[column.id]?.filterOptions;
            if(filterOptions) return filterOptions

            if(['client','contractor'].includes(column.key)) return this.organisationFilterOptions || [];
            if(masterId === 'contractor-branch') {
                const branches = this.$store.getters.getCurrentOrganisation.childs || [];
                return branches.map(branch => {
                    return {
                        label: `${branch.address.zip}, ${branch.address.city}`,
                        value: branch.id
                    }
                })
            }
            if(column.type === 'indicator') return column.indicator.answerValues || [];
            if(column.key.includes('Status')) return this.statusFilterOptions || [];
            if(column.key.includes('ProjectName')) return this.projectsFilterOptions || [];
            if(column.key.includes('members')) return this.membersFilterOptions || [];
            return []
        },
        _setColumns: _.debounce(function() {
            this.setColumns();
        }, 100),
        setColumns() {
            const configuration = this.$store.getters.getProjectConfiguration;
            const columns = configuration.columns || [];

            this.columns = columns.map(column => {
                const filterType = this.getColumnFilterType(column);
                const filterOptions = this.getColumnFilterOptions(column);
                const loading = this.columnsFilterData[column.key]?.loading || false;
                const disableSorting = ['checkbox','radio','organisation','user'].includes(column.info.answerType);
                const filterData = this.columnsFilterData[column.key];
                const align = column.align || 'left';
                const showSearchInput = column.indicator?.masterId !== 'contractor-branch';
                const stretch = column.info.stretch || false;
                const width = column.id === 'norm' ? '80px' : null;

                return {
                    field: column.id,
                    label: this.$t(column.info.name),
                    key: column.key,
                    align,
                    filter: filterType !== null,
                    filterType,
                    filterOptions: filterOptions,
                    loading,
                    disableSorting,
                    showSearchInput,
                    stretch,
                    width,
                    ...filterData
                }
            });
        },
        async getIndicatorColumnsFilterOptions() {
            const configuration = this.$store.getters.getProjectConfiguration;
            const columns = configuration.columns || [];
            const indicatorIds = columns.filter(column => column.type === 'indicator' && !['client','contractor'].includes(column.key)).map(column => column.key);

            const indicators = await this.$apollo.query({ 
                query: GET_INDICATORS,
                variables: {
                    where: {
                        id__in: indicatorIds
                    }
                }
            })
            .then(result => result.data.indicators)
            .catch(error => this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het klaarzetten van de tabel' })) || [];

            for(let i = 0; i < indicators.length; i++) {
                const { name, answerType, answerValues = [] } = indicators[i];
                const column = this.columns.find(column => column.field.toLowerCase() === name.toLowerCase());
                if(!column) continue;

                this.fillColumnsFilterData(column.key, 'filterOptions', answerValues);
            }
        },
        async getIndicatorMaxValues() {
            const configuration = this.$store.getters.getProjectConfiguration;
            const columns = configuration.columns || [];
            const indicatorIds = columns.filter(column => column.type === 'indicator' && column.indicator.answerType === 'number').map(column => column.key);
            const projectIds = this.allMinimalProjects.map(project => project.id);

            try {
                for(let i = 0; i < indicatorIds.length; i++) {
                    const values = await this.$apollo.query({
                        query: GET_VALUES,
                        variables: {
                            where: {
                                AND: [
                                    { projectId__in: projectIds },
                                    { indicatorId: indicatorIds[i] },
                                    { value__nin: [null, undefined, ''] }
                                ]
                            },
                            sort: 'value__DESC',
                            first: 1
                        }
                    })
                    .then(result => result.data.values || []);

                    const highestValue = values[0] || {};
                    this.fillColumnsFilterData(indicatorIds[i], 'sliderMax', highestValue.value || 10);
                }
            } catch(error) {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het instellen van de maximale waardes' })
            }
        },
        fillColumnsFilterData(columnId, dataKey, value) {
            if(!Object.keys(this.columnsFilterData).includes(columnId)) this.columnsFilterData[columnId] = {};
            this.columnsFilterData[columnId][dataKey] = value;
            if(dataKey === 'filterOptions') this.columnsFilterData[columnId].loading = false;
            this._setColumns();
        }
    },
    computed: {
        canCreateProject: function() {
            return this.organisationType === 'client' && !this.hybridOrganisation() || true
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        },
        canViewFinishedProjects: function() {
            const { products } = this.$store.getters.getCurrentOrganisation;

            if (this.organisationType !== 'contractor') return true;

            const slugs = products.map(product => product.slug);
            const allowedProducts = ['crow_contractor', 'pd_basis', 'pd_full'];

            return allowedProducts.some(product => slugs.includes(product));
        },
        formattedProjects: function() {
            return this.paginatedProjects.map(project => {
                if (this.canViewFinishedProjects) return project;
                return {
                    ...project,
                    __customStyling: project.status === 'finished' ? 'background-color: #d6d6d6' : ''
                };
            });
        },
        canCreateNewProject: function() {
            return canCreateNewProject()
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        },
        freemiumOrganisation: function() {
            return freemiumOrganisation()
        },
        exportButtonText: function() {
            if (this.exportingProjects) {
                const exportingPercentage = Math.round(100 * this.exportedProjects.length / this.projectsMinimal.length)
                return `${exportingPercentage}%`;
            } else {
                return 'Exporteren';
            }
        },
    },
    watch: {
        initializedTableLength: function() {
            if(this.initializedTableLength) this.handleUpdateTable();
        }
    },
    created() {
        this.setColumns();
        this.initializeFilterData();
    },
    mounted() {
        this.paginatedTableLength = getCalculatedRows(10);
        this.initializedTableLength = true;
    }
};
</script>

<style scoped lang="scss">
@import '@/components/qds/assets/style/_variables.scss';

.modal {
    width: 800px;

    .header,
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header {
        padding-bottom: 32px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 10px 24px 0;
        max-height: calc(80vh - 160px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.page-padding {
    padding-bottom: 0;
}

.zerostate {
    margin-top: 100px;
}

.boldText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

h2 {
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.crow-logo {
    width: 50px;
}

.icon .button_icon {
    padding-top: 0px !important;
}
progress[value] {
  --w: 100%; /* the width*/  
  --color:  /* the progress color */
    linear-gradient(90deg,#00A1AE,#006168) 0/var(--w);
  --background: lightgrey; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  height: 30px;
  margin: 10px;
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 0.7em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 0.7em;
  background: var(--color);
}

</style>
