<template>
    <div class="page-padding">
        <div class="flex space-between mb-l">
            <h2>Rapportage</h2>
        </div>

        <div v-if="!isCrowMasterOrganisation" class="top-wrapper">
            <div class="left">
                <div class="custom-input">
                    <q-icon type="search" width="20" height="20"></q-icon>
                    <input
                        type="text"
                        class="input"
                        v-model="searchValue"
                        @input="handleSearchInput"
                        placeholder="Zoek een rapportage"
                    />
                </div>
            </div>

            <div class="right">
                <q-dropdown
                    v-model="reportSort"
                    :options="sortOptions"
                    @input="handleSortInput"
                    placeholder="Sorteren"
                    size="small"
                    autocomplete
                ></q-dropdown>
                <div class="button">
                    <q-button @click="createReport" :loading="creatingReport">Maak nieuwe rapportage aan</q-button>
                </div>
            </div>
        </div>
        <div v-if="hasReports || (loading && !hasReports)" class="tiles-container">
            <tile
                v-for="(report, index) in reports"
                :key="report.id"
                :tile="report"
                :hasCrowReport="report.id === 'crow-report-default'"
                @deleteReport="handleDeleteReport"
                @editReport="handleEditReport"
                @click="handleReportSelect(report)"
                class="tile"
                :style="`--animation-delay: ${index * 0.025}s;`"
            ></tile>
        </div>

        <div v-if="!loading && !hasReports && !isCrowMasterOrganisation" class="no-reports-container">
            <q-zerostate
                class="zerostate"
                img="/statics/img/survey_gradient.svg"
                title="Rapportages"
                description="Je hebt nog geen rapportage aangemaakt"
            >
                <q-button @click="handleCreateReport" :loading="creatingReport">Maak nieuwe rapportage aan</q-button>
            </q-zerostate>
        </div>

        <div v-if="searchValue !== '' && hasReports && reports.length == 0" class="empty-search">
            <p>Er zijn geen rapportages die overeenkomen met '{{ searchValue }}'</p>
        </div>

        <!-- <div class="snapshot">
            <h3>Snapshots</h3>
            <div class="snapshot-container">
                <snapshot
                    v-for="(snapshot, index) in updatedSnapshots"
                    :key="index"
                    :snapshot="snapshot"
                    @deleteSelection="handleDeleteSelection"
                ></snapshot>
            </div>
        </div> -->

        <q-popup v-if="showDeleteModal" @close="showDeleteModal = false" width="40%">
            <div class="delete-modal">
                <div class="body">
                    <h4 class="title">Weet je zeker dat je deze rapportage wilt verwijderen?</h4>
                    <p class="about">
                        Als je de rapportage <b>{{ selectedReport.name }}</b> verwijderd gaat de opgeslagen data
                        verloren.
                    </p>
                </div>
                <div class="footer">
                    <q-button variation="blank" @click="showDeleteModal = false">Terug</q-button>
                    <q-button variation="danger" @click="deleteReport(selectedReport)">Verwijderen</q-button>
                </div>
            </div>
        </q-popup>
    </div>
</template>

<script>
import Tile from './ReportTile.vue';

import { CREATE_REPORT, DELETE_REPORT } from '@/graphql/mutations';
import { REPORTS } from '@/graphql/queries';

import _ from 'lodash';

export default {
    name: 'report-dashboard',
    data() {
        return {
            isCrowMasterOrganisation: this.$store.getters.getCurrentOrganisation.id === 'crow-master-organisation',
            allUsers: [],
            reports: [],
            configuration: {
                organisationId: this.$store.getters.getCurrentOrganisation.id,
                resultType: 'projects',
                conditions: {
                    references: [],
                    questions: [],
                    forms: [],
                    users: [],
                    projects: [],
                    formTemplates: [],
                },
                projection: [
                    {
                        id: 'projectName',
                        name: 'projectName',
                        type: 'projectName',
                        visible: true,
                        filterable: true,
                        checked: true,
                    },
                ],
            },
            loading: true,
            hasReports: false,
            reportSort: null,
            sortOptions: [
                {
                    label: 'Geen',
                    value: '',
                },
                {
                    label: 'Alfabetisch (oplopend)',
                    value: 'name__ASC',
                },
                {
                    label: 'Alfabetisch (aflopend)',
                    value: 'name__DESC',
                },
            ],
            searchValue: '',
            creatingReport: false,
            windowWidth: window.visualViewport.width,
            showDeleteModal: false,
            selectedReport: null,
        };
    },
    components: {
        Tile,
    },
    methods: {
        createReport() {
            this.handleCreateReport();
        },
        handleSearch() {},
        handleDeleteReport(deleteReport) {
            this.selectedReport = deleteReport;
            this.showDeleteModal = true;
        },
        deleteReport(deleteReport) {
            this.$apollo
                .mutate({
                    mutation: DELETE_REPORT,
                    variables: {
                        id: deleteReport.id,
                    },
                })
                .then((result) => {
                    this.reports = this.reports.filter((report) => report.id !== deleteReport.id);
                    this.updateReports();
                    this.getReports();
                    this.showDeleteModal = false;
                    this.selectedReport = null;
                })
                .catch((error) => console.log(error));
        },
        handleReportSelect(report) {
            if (report.empty || report.loading) return;

            if (report.id === 'crow-report-default') {
                if (this.isCrowMasterOrganisation) return this.$router.push(`/reports/${report.id}`);
                else
                    return this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Je hebt geen toegang tot deze rapportage',
                    });
            }

            if (report.name === 'Rapportage formulieren')
                return this.$router.push(`/reports/${report.id}?name=${report.reportName}`);

            if (!report.name) return this.$router.push(`/reports/${report.id}/edit`);

            this.$router.push(`/reports/${report.id}?name=${report.name}`);
        },
        handleEditReport(report) {
            this.$router.push(`/reports/${report.id}/edit`);
        },
        handleCreateReport() {
            this.creatingReport = true;
            this.$apollo.mutate({
                mutation: CREATE_REPORT,
                variables: {
                    organisationId: this.$store.getters.getCurrentOrganisation.id,
                    configuration: this.configuration
                }
            })        
            .then(result => {
                localStorage.setItem('lastCreatedReportId', result.data.report_create.id);
                this.$router.push(`/reports/${result.data.report_create.id}/create`);
            })
            .catch(error => {
                this.creatingReport = false;
            })
        },
        getReports() {
            const hasPDFullProduct = this.$store.getters.hasPDFullProduct;
            const lastCreatedReportId = localStorage.getItem('lastCreatedReportId');
            const where = {};
            let formTemplatesReport = null;

            if (this.searchValue) where['name__contains'] = this.searchValue;

            const sort = [this.reportSort];

            this.$apollo
                .query({
                    query: REPORTS,
                    variables: {
                        where,
                        sort,
                        caseSensitive: false,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    if (!this.hasReports) this.hasReports = result.data.reports.length > 0;

                    let reports = result.data.reports.filter(
                        (report) =>
                            report.id !== lastCreatedReportId &&
                            (report.id !== 'crow-report-default' || this.isCrowMasterOrganisation)
                    );

                    const filteredFormTemplateReport = reports.filter(
                        (report) => report.name === 'Rapportage formulieren'
                    );

                    if (hasPDFullProduct && filteredFormTemplateReport.length === 0) {
                        const invisibleReports = reports.filter((report) => report.isFormTemplateResultset === true);

                        if (invisibleReports.length === 0) {
                            this.reports = reports;
                            return;
                        } else {
                            const firstInvisibleReport = invisibleReports[0];

                            formTemplatesReport = {
                                id: firstInvisibleReport.id,
                                name: 'Rapportage formulieren',
                                reportName: firstInvisibleReport.name,
                                organisationId: this.$store.getters.getCurrentOrganisation.id,
                                owners: [
                                    {
                                        firstName: 'Qfact',
                                        lastName: '',
                                    },
                                ],
                                roles: [],
                                users: [],
                                configuration: [],
                                lastUpdated: Date.now(),
                            };
                            reports = [...reports, formTemplatesReport];

                            const formTemplatesReportIndex = reports.findIndex(
                                (report) => report.name === 'Rapportage formulieren'
                            );

                            reports.splice(formTemplatesReportIndex, 1);
                            reports.unshift(formTemplatesReport);

                            reports = reports.filter((report) => report.isFormTemplateResultset !== true);
                            this.reports = reports;
                        }
                    } else {
                        this.reports = reports;
                    }

                    this.loading = false;
                    this.updateReports();
                })
                .catch((error) => {
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er ging iets fout tijdens het ophalen van de rapportages',
                    });
                });
        },
        handleSearchInput: _.debounce(function () {
            this.getReports();
        }, 400),
        handleSortInput() {
            this.getReports();
        },
        getRowLength() {
            let rowLength = 3;
            if (this.windowWidth >= 1600) {
                return 3;
            }
            if (this.windowWidth >= 1170) {
                return 2;
            }
            return rowLength;
        },
        updateReports() {
            if (this.loading) return;
            const rowLength = this.getRowLength();

            let reports = [...this.reports];

            while (reports.length % rowLength !== 0 || reports.length < rowLength * 2) {
                reports.push({ empty: true });
            }

            this.reports = reports;
        },
    },
    created() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.visualViewport.width;
            this.updateReports();
        });

        const rowLength = this.getRowLength();
        for (let i = 0; i < rowLength * 2; i++) {
            if (!this.reports[i]) this.reports.push({ loading: true });
        }
        this.updateReports();
        this.getReports();
    },
};
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

h2 {
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.no-reports-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 80px;
}

.empty-search {
    text-align: center;

    p {
        color: #a0a0a0;
        font-size: 15px;
    }
}

.top-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-width: 40%;

        .custom-input {
            display: flex;
            align-items: center;
            gap: 6px;
            width: 100%;
            background-color: $color-grey-1;
            color: $color-grey-5;
            border-radius: $radius-default;
            padding: 8px 0px 8px 12px;

            .input {
                width: 100%;
                outline: none;
                border: none;
                color: $color-grey-9;
                font-size: 16px;
                background-color: transparent;

                &::placeholder {
                    color: $color-grey-5;
                }
            }
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
}
.line {
    width: 100%;
}
.snapshot-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px 20px;
    margin: 20px 0px 20px 0px;
}
.tiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-template-rows: repeat(auto-fill, 200px);
    grid-row-gap: 40px;
    grid-column-gap: 30px;
    margin: 30px 0px 20px 0px;
    padding-bottom: 50px;
}

.delete-modal {
    .body {
        height: 180px;
        .title {
            margin-bottom: 30px;
        }
        .about {
            margin-bottom: 20px;
        }
    }
    .footer {
        display: flex;
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
        justify-content: space-between;
    }
}

@media only screen and (max-width: 1180px) {
    .top-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .left {
            max-width: unset;
        }

        .right {
            display: flex;
            // margin: 20px 0px 0px 50px;

            .button {
                // margin: 20px 0px 0px -50px;
            }
        }
    }
}
</style>
