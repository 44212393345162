/**
 * This utils file contains helper methods specific for forms
 */

/**
 * This method determines which component to load and status to give according the state of the form.
 */
const formStatus = (form) => {
    const companions = form.companions || [];
    const responses = form.responses || [];

    /**
     * If the form has status concept, it means no answer has been filled yet
     */
    if (form.status === 'concept') return {
        component: 'fill',
        status: 'concept'
    }
   
    /**
     * If the form has status open, it needs to be filled first
     */
    if (form.status === 'open' && responses.length === 0) return {
        component: 'fill', 
        status: 'startFilling'
    }

    else if (form.status === 'open' && responses.length > 0) return {
        component: 'fill',
        status: 'filling'
    
    }

    /**
     * If no companion exists and the form is finished, it means this project is migrated with and old CROW QuestionaireID,
     * Those projects don't have a companion.
     */
    if (companions.length === 0 && form.status === 'finished') return {
        component: 'overview',
        status: 'finished'
    }
    
    /**
     * When the form has been filled but the companion form has no responses yet, wait for companion to start.
     */
    if(companions.length === 0 || ((companions[0].responses && companions[0].responses?.length === 0) && ['concept', 'open'].includes(companions[0].status))) return {
        component: 'overview', 
        status: 'waitingForCompanionToStart'
    }

    /**
     * When the form has been filled but the companion form does not exist or has status 'open', wait for companion.
     */
    if (companions.length === 0 || ['concept', 'open'].includes(companions[0].status)) return {
        component: 'overview',
        status: 'waitingForCompanionFill'
    }

    /**
     * If you have agreed (or disagreed) but the companion has not yet, wait for the companion
     */
    if (form.status === 'agreed' && companions[0].status === 'completed') return {
        component: 'overview',
        status: 'waitingForCompanionDiscussion'
    }

    /**
     * If the companion has disagreed, you are in revision and have to change your answers
     */
    if(companions[0].status === 'disagreed' || form.status === 'crow_admin_disagreed') return {
        component: 'overview',
        status: 'revision'
    }

    /**
     * If you have disagreed, wait for the companion revision
     */
    if (form.status === 'disagreed' || companions[0].status === 'crow_admin_disagreed') return {
        component: 'overview',
        status: 'waitingForCompanionRevision'
    }

    /**
     * unless any party has disagreed, you will be in the discussion phase until it is finished or waiting for the crow admin
     */
    if (!['agreed','pending_crow_admin','crow_admin_disagreed','finished'].includes(companions[0].status) || !['agreed','pending_crow_admin','crow_admin_disagreed','finished'].includes(form.status)) return {
        component: 'overview',
        status: 'discussion'
    }

    if (form.status === 'pending_crow_admin' && companions[0].status === 'pending_crow_admin') return {
        component: 'overview',
        status: 'waitingForCrowAdmin'
    }

    /**
     * If the status has finally been set to finished. Which happens when both forms have 'agreed'
     * 
     * both forms having status 'agreed' is the same as 'finished'
     */
    if ((form.status === 'agreed' && companions[0].status === 'agreed') || form.status === 'finished') return {
        component: 'overview',
        status: 'finished'
    }

    /**
     * fallback incase something goes or wrong
     */
    return {
        component: 'error',
        status: 'error'
    }
}

module.exports = {
    formStatus
}