<template>
    <div class="row full-height middle-xs q-background">
        <div class="center-xs vertical-center login-sider">
            <div class="announcement-card fade-and-slide" style="--animation-delay: 0s">
                <h3 class="fade-and-slide" style="margin-top: 24px; margin-left: 24px; font-size: 23px; --animation-delay: 0s">CROW Better Performance voor aannemers</h3>
                <div class="webinar-card">
                    <h3 class="title fade-and-slide" style="--animation-delay: .1s">Weet binnen 30 minuten wat jullie hier als bedrijf mee kunnen</h3>
                    <div class="content">
                        <div class="left">
                            <img src="/statics/img/Foto-Leen.png" alt="" class="webinar-image fade-and-slide leen"  style="--animation-delay: .2s">
                            <div class="info fade-and-slide">
                                <div class="line fade-and-slide"  style="--animation-delay: .33s">
                                    <p>Datum</p>
                                    <p class="bold">Zelf in te plannen</p>
                                </div>
                                <div class="line fade-and-slide"  style="--animation-delay: .36s">
                                    <p>Duur</p>
                                    <p class="bold">30 minuten</p>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <p class="about fade-and-slide" style="--animation-delay: .4s">Interesse in het gebruik van CROW Better Performance? Meer dan 120 bedrijven in de GWW zijn inmiddels aangesloten en meten op hun eigen initiatief CROW Better Performance. In een half uur laat Leen Valk je zien wat jullie hier als bedrijf mee kunnen. Klik op de knop hieronder om zelf een datum en tijdstip voor een online afspraak van 30 minuten in te plannen.</p>
                            <q-button class="fade-and-slide" style="--animation-delay: .5s" variation="primary" @click="to('https://calendly.com/qfact-leen/uitleg-crow-better-performance-voor-aannemers')">Plan een afspraak</q-button>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div v-if="Date.now() < 1695074400000" class="webinar-card">
                    <h3 class="title fade-and-slide" style="--animation-delay: .1s">EMVI-schrijven: Next level</h3>
                    <div class="content">
                        <div class="left">
                            <img src="/statics/img/Astrid-Shyekj-scaled.jpeg" alt="" class="webinar-image fade-and-slide nicolette" style="--animation-delay: .2s">
                            <div class="info">
                                <div class="line fade-and-slide"  style="--animation-delay: .33s">
                                    <p>Datum</p>
                                    <p class="bold">19 september</p>
                                </div>
                                <div class="line fade-and-slide"  style="--animation-delay: .36s">
                                    <p>Locatie</p>
                                    <p class="bold">Van der Valk Veenendaal</p>
                                </div>
                                <div class="line fade-and-slide"  style="--animation-delay: .39s">
                                    <p>Prijs</p>
                                    <p class="bold">€675,- (excl. BTW)</p>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <p class="about fade-and-slide" style="--animation-delay: .4s">
                                Na deze 1-daagse ben je in staat de juiste accenten te leggen in een EMVI-plan en altijd de juiste snaar te raken en weet je hoe je (nog) vaker als winnaar uit de bus kunt komen bij tenders.
                            </p>
                            <q-button class="fade-and-slide" style="--animation-delay: .5s" variation="primary" @click="to('https://bouwmeesters.nu/product/emvi-schrijven-next-level/')">Aanmelden voor deze cursus</q-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="center-xs login-main">
            <q-section class="top-bar" hPadding="30" vPadding="30">
                <p class="semi-bold" @click="toClientRegister" style="cursor: pointer">Ben je een <span class="client">opdrachtgever</span>? Bekijk <span class="client-here">hier</span> hoe je een account aanmaakt</p>
                <q-button @click="toContractorRegister" variation="light" size="small">Account aanmaken als opdrachtnemer</q-button>
            </q-section>
            <q-section class="login-form">
                <div v-if="appDisabled" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"></q-icon>

                    <p v-html="appDisabledMessage"></p>
                </div>
                <div v-else-if="appInfoMessage" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"></q-icon>

                    <p v-html="appInfoMessage"></p>
                </div>
                <div v-if="appDisabled" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"> </q-icon>

                    <p v-html="appDisabledMessage"></p>
                </div>
                <h2 class="py-s">
                    Welkom terug bij Qfact
                </h2>
                <q-input labelPosition="inside"
                    v-model="email"
                    inputId="email"
                    inputType="email"
                    placeholder="voorbeeld@email.nl"
                    size="medium"
                    autofocus
                    :disabled="appDisabled"
                    >E-mailadres</q-input
                >
                <q-input labelPosition="inside"
                    v-model="password"
                    inputType="password"
                    placeholder="Wachtwoord"
                    size="medium"
                    :disabled="appDisabled"
                    @keyup.enter.native="handleLogin"
                    >Wachtwoord</q-input
                >
            </q-section>

            <q-section class="login-form space-between reset-password">
                <q-button to="/resetpassword" class="reset-password-button" variation="blank">Wachtwoord vergeten?</q-button>
            </q-section>

            <q-section class="login-form space-between" paddingBottom="0">
                <q-button @click="handleLogin" :loading="awaitLogin" class="login-button" :disabled="appDisabled">Inloggen</q-button>
            </q-section>

            <q-section class="login-form space-between" paddingBottom="0" paddingTop="30">
                <p class="client-connect-organisation" @click="toConnectClient">Een nieuwe organisatie koppelen aan je account? Bekijk <span class="client-here">hier</span> hoe je dit doet</p>
            </q-section>

            <q-section class="bottom-bar row" hPadding="0" vPadding="0">
                <div class="col-sm-5"></div>
                <div class="col-sm-7 center-xs">
                    <img class="qfact-logo-login" src="/statics/img/Qfact-logo.svg" alt="" />
                </div>
            </q-section>
            <notifications />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { LOGIN } from '@/graphql/mutations';
import Notifications from '@/components/Notifications';
import ArrowButton from '@/components/ArrowButton';
import { handleLoginSuccess, extractError } from '@/assets/js/utils';

export default {
    components: {
        Notifications,
        ArrowButton
    },
    data() {
        return {
            email: '',
            password: '',
            awaitLogin: false,
            appDisabled: process.env.BLOCK_LOGIN || false,
            appDisabledMessage: process.env.BLOCK_MESSAGE || 'Qfact is momenteel in onderhoudsmodus.',
            appInfoMessage: process.env.LOGIN_MESSAGE
        };
    },
    mounted() {
        document.getElementById('email').focus();
        const { error } = this.$route.query;
        if (error) this.$store.commit('notify', { type: 'warning', message: error });
    },
    methods: {
        to(url) {
            window.open(url, '_blank');
        },
        handleLogin() {
            const vm = this;

            if (this.appDisabled) {
                return this.$store.commit('notify', { type: 'info', message: 'Inloggen is momenteel niet mogelijk.' });
            }

            if (this.email == '' || this.password == '') return;

            this.awaitLogin = true;
            this.$apollo
                .mutate({
                    mutation: LOGIN,
                    variables: { email: this.email, password: this.password },
                })
                .then((response) => {
                    if (response.data.user_login.twofactorRequired) {
                        vm.$store.commit('setTwofactorJwt', response.data.user_login.jwt);

                        if (response.data.user_login.twofactorQRcode) {
                            vm.$store.commit('setTwofactorQrCode', response.data.user_login.twofactorQRcode);
                            vm.$router.push('/twofactorconfig');
                        } else {
                            // check if 2FA needs config
                            vm.$router.push('/twofactor');
                        }
                    } else {
                        return handleLoginSuccess(vm, response.data.user_login);
                    }
                })
                .catch(error => {
                    vm.awaitLogin = false;

                    const errors = error.graphQLErrors;
                    const messages = errors.map(error => error.message);

                    if (!messages || messages.length == 0) return
                    
                    messages.forEach(message => {
                        if(message.toLowerCase().includes('niet')) {
                            return this.$store.commit('notify', {
                                type: 'danger',
                                message: message
                            });
                        }
                        return this.$store.commit('notify', {
                            type: 'danger',
                            message: 'Oeps, we doen iets niet goed. Neem contact op met de helpdesk als deze foutmelding blijft terugkomen.'
                        })
                    });
                });
        },
        toCourse(to) {
            window.location.href = to;
        },
        toClientRegister() {
            window.open(` https://kennisbank.qfact.nl/nl/article/account-aanmaken-als-opdrachtgever`, '_blank');
        },
        toContractorRegister() {
            this.$router.push('/register')
        },
        toConnectClient() {
            window.open(`https://kennisbank.qfact.nl/nl/article/een-nieuwe-opdrachtgeversorganisatie-koppelen-aan-je-account`, '_blank');
        },
    }
};
</script>

<style lang="scss" scoped>
.course-header {
    display: flex;
    align-items: center;
    color: #8b8b8b;

    & img {
        margin-left: 10px;
        margin-top: 2px;
    }
}

.qfact-logo-login {
    height: 50px;
    z-index: -1;
}

.full-height {
    height: 100vh;
}

.q-background {
    background-color: #fff;
    background-image: url('/statics/img/qfact_hero.png');
    background-size: 1400px;
    background-position: -600px;
    background-repeat: no-repeat;
}

.login-sider {
    justify-content: center;
    height: 100%;
    width: 45%;
}

.top-bar {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    justify-content: flex-end;
    margin-bottom: 200px;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100px;
    width: 100%;
}

.notifs {
    position: absolute;
    padding: 20px;
    right: 10px;
}

.login-form {
    margin: auto;
    text-align: left;
    width: 430px;

    .message {
        animation: fadeIn 800ms forwards;
        animation-delay: 1000ms;
        opacity: 0;
        margin-top: -142px;
        margin-bottom: 24px;
        border: 1px solid rgb(253, 216, 104);
        background: rgb(253, 225, 139);
        border-radius: 5px;
        padding: 16px;

        p {
            margin-top: 8px;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
                z-index: 1;
            }
            100% {
                opacity: 1;
                z-index: 5;
            }
        }
    }
}

.semi-bold {
    font-weight: 500;
    margin-right: 30px;
    line-height: 30px;
    color: #aeb5bc;
}

.qfact-logo {
    font-weight: 800;
    font-size: 30px;
    color: #dee2e6;
    transition: all 0.2s ease;

    &:hover {
        color: #495057;
        cursor: pointer;
    }
}

.fade {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 400ms;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin-right: -40px;
}

.fade-and-slide {
    opacity: 0;
    animation: fadeAndSlide .4s ease-out forwards var(--animation-delay);
    -webkit-animation: fadeAndSlide .4s ease-out forwards var(--animation-delay);
}

.announcement-card {
    background-color: white;
    width: 100%;
    max-width: 680px;
    max-height: 100vh;
    border-radius: 12px;
    margin-left: 3vw;
    text-align: left;
    z-index: 10;
    border: 1px solid #48a09a;
    box-shadow: 0 2px 15px #92cbc7;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    h3 {
        color: #48a09a;
        font-size: 20px;
    }

    p {
        margin: 10px 0;
        font-size: 13px;

        .link {
            opacity: 0.8;
        }
    }


    img {
        width: 100%;
        margin-top: 6px;
        border-radius: 4px;
    }

    .divider {
        height: 1px;
        inset: auto 0;
        background-color: #48a09a;
    }

    .webinar-card {
        padding: 25px 25px;

        .title {
            margin-bottom: 8px;
            font-size: 18px;
        }

        .content {
            display: flex;
            gap: 10px;
            margin: 4px 0;

            .left {
                display: flex;
                flex-direction: column;
                width: 40%;
    
                .webinar-image {
                    max-height: 130px;
                    max-width: 240px;
                    object-fit: cover;

                    &.leen {
                        object-position: center 10%;
                    }
                    &.nicolette {
                        object-position: center 35%;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin: 12px 0;

                    .line {
                        display: flex;
                        align-items: center;

                        .flex-column {
                            display: flex;
                            flex-direction: column;

                            p {
                                width: 100%;
                            }
                        }

                        .bold {
                            font-weight: 500;
                            flex-grow: 1;
                            width: 120px;
                        }
                        p {
                            width: 70px;
                            margin: 0;
                            line-height: 18px;
                        }
                    }
                }
            }
            .right {
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                margin-left: 6px;

                .about {
                    margin: 0 0 12px 0;
                }
                .button {
                }
            }
        }
    }
}

@keyframes fadeAndSlide {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@-webkit-keyframes fadeAndSlide {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.login-main {
    width: 55%;
}

.client {
    color: #00A1AE;
}

.client-here {
    text-decoration: underline;
}

.reset-password {
    justify-content: flex-end;
}

.reset-password-button {
    padding: 0 0 20px 0!important;
}

.login-button {
    width: 100%;
}

.client-connect-organisation {
    color: #757575;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}
</style>
