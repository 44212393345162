<template>
    <div class="reference-letter-documents">
        <p>Bijlagen</p>
        <document-panel :documents="formattedDocuments" :canDownload="true" @onSuccess="onSuccessfullUpload" @onError="onUploadError" @deleteDocument="handleDeleteDocument"/>
    </div>
</template>

<script>
import { ADD_REFERENCELETTER_DOCUMENT, REMOVE_REFERENCELETTER_DOCUMENT } from '@/graphql/mutations'
import DocumentPanel from '@/components/DocumentsPanel.vue';
import gql from 'graphql-tag';
import { extractError } from '../../../assets/js/utils';

export default {
    name: 'reference-letter-documents',
    components: {
        'document-panel': DocumentPanel
    },
    data() {
        return {
            referenceLetterId: this.$route.params.referenceLetterId,
            documents: [],
        }
    },
    methods: {
        onSuccessfullUpload(document) {
            const {
                id: documentId,
                originalFileName,
                fileName
            } = document;

            this.$apollo.mutate({
                mutation: ADD_REFERENCELETTER_DOCUMENT,
                variables: {
                    id: this.referenceLetterId,
                    documentId,
                    originalFileName,
                    fileName
                },
            })
            .then(result => {
                this.$store.commit('notify', { type: 'success', message: 'Bijlage succesvol toegevoegd' })
                this.getDocuments();
            })
            .catch(error => {
                this.onUploadError(error.message)
            })
        },
        handleDeleteDocument(document) {
            this.$apollo.mutate({
                mutation: REMOVE_REFERENCELETTER_DOCUMENT,
                variables: {
                    id: this.referenceLetterId,
                    documentId: document.id
                }
            })
            .then(result => {
                this.documents = result.data.referenceLetter_removeDocument.documents;
                this.$store.commit('notify', { type: 'success', message: 'Bijlage succesvol verwijderd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: extractError(error)})
            })
        },
        onUploadError(error) {
            this.$store.commit('notify', { type: 'danger', message: extractError(error)})
        },
        getDocuments() {
            this.$apollo.query({
                query: gql`
                    query GetReferenceLetter($id: String!) {
                        referenceLetter(id: $id) {
                            documents
                        }
                    }
                `,
                variables: {
                    id: this.referenceLetterId
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.documents = result.data.referenceLetter.documents || [];
            })
            .catch(error => {
                extractError(error)
            })
        }
    },
    computed: {
        formattedDocuments: function() {
            return this.documents.map(document => {
                return {
                    id: document.documentId,
                    name: document.originalFileName,
                    fileName: document.fileName
                }
            });
        }
    },
    created() {
        this.getDocuments();
    }
}
</script>

<style scoped>

p {
    font-weight: 500;
}

</style>