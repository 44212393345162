<template>
    <div id="filter-menu" class="filter-menu" :class="[{ opened: showFilterMenu }]">
        <div class="back" @click="handleEmitShow">
            <div class="left">
                <q-icon type="filterIcon"></q-icon>
                <div><strong>Filters</strong></div>
                <jump-transition>
                    <q-tag v-if="activeFiltersAmount !== 0" variation="warning" size="small">{{ activeFiltersAmount }}</q-tag>
                </jump-transition>
            </div>
            <div class="right">
                <q-icon class="arrow-right" width="12px" height="12px" type="small-arrow-up"></q-icon>
            </div>
        </div>

        <indicator-table
            v-model="referenceContexts"
            :report="report"
            :reportPageFilterBar="true"
            :loading="loading"
            :filter="filter"
            filterBarType="version_2"
            searchable
            answerValue
            @input="onTableUpdated"
            @filterUpdated="handleFilterUpdated"
            @searchUpdated="handleSearchUpdated"
        ></indicator-table>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

import IndicatorTable from './indicatortable/IndicatorTable.vue';

export default {
    name: 'filter-menu',
    components: {
        IndicatorTable,
    },
    props: {
        report: {
            type: Object,
            required: true,
        },
        showFilterMenu: {
            type: Boolean,
            required: true,
        },
        resultsFilterOptions: {
            type: Object,
            required: false,
        },
        activeFiltersAmount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: true,
            indicators: [],
            referenceContexts: [],
            filterInput: '',
            filterOptionSearch: '',
            filterOption: 'Categoriën',
            initialised: false,
            filter: {
                search: '',
                indicator: 'reference',
            },
            filterIndicators: [],
            activeProducts: this.$store.getters.getActiveProducts,
            activeOrganisation: this.$store.getters.getCurrentOrganisation.id,
        };
    },
    methods: {
        resetInput() {
            this.filterInput = '';
        },
        onTableUpdated(contexts) {
            this.referenceContexts = contexts;
            this.$emit('updateFilter', contexts);
        },
        handleEmitShow() {
            this.$emit('toggleMenu');
        },
        async getIndicators() {
            try {
                const { indicators, labels } = await new Promise(async (resolve, reject) => {
                    let indicators = [];
                    const indicatorType = this.filter.indicator;
                    let relevantIndicatorIds = this.report.configuration.projection
                        .filter(projection => projection.indicator && projection.indicator.type === indicatorType && projection.filterable)
                        .map((projection) => projection.indicator.id);
    
                    let relevantIndicatorMasterIds = this.report.configuration.projection
                        .filter(
                            (projection) =>
                                !projection.visible &&
                                projection.indicator &&
                                projection.indicator.type === indicatorType &&
                                projection.indicator.masterId &&
                                projection.filterable
                        )
                        .map((projection) => projection.indicator.masterId);
    
                    if (relevantIndicatorMasterIds && relevantIndicatorMasterIds.length > 0) {
                        for (let i = 0; i < relevantIndicatorMasterIds.length; i++) {
                            relevantIndicatorIds.push(relevantIndicatorMasterIds[i]);
                        }
                    }
    
                    const indicatorsResult = await this.$apollo.query({
                        query: gql`
                            query Indicators($where: JSON!) {
                                indicators(where: $where) {
                                    id
                                    masterId
                                    name
                                    isMaster
                                    answerType
                                    labelIds
                                    type
                                    answerValues {
                                        value
                                        label
                                    }
                                    organisationId
                                }
                            }
                        `,
                        variables: {
                            where: {
                                AND: [
                                    { id__in: relevantIndicatorIds },
                                    { isMaster__nin: [true] }
                                ]
                            }
                        }
                    });
    
                    indicators = indicatorsResult.data.indicators;
    
                    let labelIds = [];
    
                    indicators.forEach((indicator) => {
                        labelIds = [...labelIds, ...indicator.labelIds];
                    });
    
                    const labelsResult = await this.$apollo.query({
                        query: gql`
                            query Labels($where: JSON!) {
                                labels(where: $where) {
                                    id
                                    masterId
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                OR: [
                                    { id__in: labelIds },
                                    { masterId__in: labelIds }
                                ]
                            },
                        },
                    });
                    const labels = labelsResult.data.labels;
    
                    resolve({ indicators, labels });
                })
                
                this.loading = false;
                this.indicators = indicators;
                this.labels = labels;
                this.updateReferenceContexts();
            } catch(error) {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de kenmerken' })
            }
        },
        handleFilterUpdated: _.debounce(function (filter) {
            this.filter = filter;

            this.getIndicators();
        }, 200),
        handleSearchUpdated(search) {
            this.filter.search = search;
        },
        async updateReferenceContexts() {
            if (!this.initialised) this.loading = true;

            const indicatorType = this.filter.indicator;

            const relevantIndicators = this.indicators.filter((indicator) => indicator.type === indicatorType);

            const contexts = this.labels.map((label) => {
                return {
                    id: label.id,
                    title: label.name,
                    masterId: label.masterId
                };
            });
            
            let referenceProjections = this.report.configuration.projection.filter(projection => 
                projection.type !== 'projectName' && 
                projection.type !== 'members' && 
                projection.type !== 'empty' &&
                projection.filterable
            );

            const awaitedContexts = await Promise.all(
                contexts.map(async (context) => {
                    return new Promise(async (resolve, reject) => {
                        const indicators = relevantIndicators.filter(indicator =>
                            indicator.labelIds.includes(context.id) || 
                            indicator.labelIds.includes(context.masterId)
                        );

                        context.items = await Promise.all(
                            indicators.map(async (indicator) => {
                                return new Promise(async (resolve, reject) => {
                                    let checked = false;
                                    let filters = { value: undefined, values: [], type: 'lt' };

                                    const relatedProjection = referenceProjections.find(
                                        (projection) => projection.indicator && projection.indicator.id === indicator.id
                                    );

                                    if (referenceProjections) {
                                        let matchingProjections = referenceProjections.find(projection =>
                                            projection.indicator && 
                                            projection.indicator.id === indicator.id
                                        );

                                        if (matchingProjections) {
                                            filters.value = matchingProjections.indicator.filters.value;
                                            filters.values = matchingProjections.indicator.filters.values;
                                        }
                                    }

                                    const organisationValueNames = ['Branch', 'Client', 'Contractor'];

                                    let answerType = indicator.answerType;

                                    const activeProductsOfOrganisation = [];

                                    this.activeProducts.forEach((product) => {
                                        activeProductsOfOrganisation.push(product.slug);
                                    });

                                    if (activeProductsOfOrganisation.includes('crow_client')) {
                                        activeProductsOfOrganisation.push('client');
                                    }

                                    if (activeProductsOfOrganisation.includes(indicator.masterId)) {
                                        let organisations = await this.getActiveOrganisation();

                                        indicator.answerValues = organisations.map((organisation) => {
                                            return {
                                                label: organisation.name,
                                                value: organisation.id,
                                            };
                                        });
                                        answerType = 'organisation';
                                    } else if (
                                        !activeProductsOfOrganisation.includes(indicator.masterId) &&
                                        organisationValueNames.includes(indicator.name)
                                    ) {
                                        let organisations = await this.getOrganisations(indicator.name);

                                        indicator.answerValues = organisations.map((organisation) => {
                                            return {
                                                label: organisation.name,
                                                value: organisation.id,
                                            };
                                        });
                                        answerType = 'organisation';
                                    }

                                    if (relatedProjection) {
                                        answerType = relatedProjection.indicator.answerType;
                                    } else {
                                        answerType;
                                    }


                                    if (indicator.id === 'intermediateMeasurementSkippedFilter' && answerType === 'checkbox') answerType = 'boolean'

                                    let answerValues = indicator.answerValues

                                    resolve({
                                        id: indicator.id,
                                        name: indicator.name,
                                        type: indicator.type,
                                        answerType,
                                        answerValues: answerValues,
                                        filters,
                                        checked,
                                        projection: relatedProjection,
                                    });
                                });
                            })
                        );
                        resolve(context);
                    });
                })
            );
        
            this.referenceContexts = awaitedContexts.filter((context) => context.items.length > 0);
            
            if (this.report.id === 'crow-report-default') {
                let filterOptions = this.resultsFilterOptions;

                let contexts = this.report.configuration.projection.filter(
                    (projection) =>
                        projection.type !== 'projectName' &&
                        projection.type !== 'members' &&
                        projection.type !== 'empty' &&
                        projection.type !== 'indicator' &&
                        projection.visible === false &&
                        projection.filterable === true
                );

                const otherAwaitedContexts = await Promise.all(
                    contexts.map(async (context) => {
                        return new Promise(async (resolve, reject) => {
                            for (let index in filterOptions) {
                                if (index === context.id) {
                                    let checked = false;
                                    if (context.answerType === 'boolean') checked = filterOptions[index].value;

                                    let answerValues = [];
                                    if (context.id === 'organisationTypeFilter') { 
                                        answerValues = [
                                            {
                                                value: 'Opdrachtgever',
                                                label: 'Opdrachtgever',
                                            },
                                            {
                                                value: 'Opdrachtnemer',
                                                label: 'Opdrachtnemer',
                                            },
                                        ];
                                    } else {
                                        answerValues = filterOptions[index];
                                    }

                                    context = {
                                        id: context.id,
                                        type: context.type,
                                        name: context.name,
                                        checked,
                                        answerValues: answerValues,
                                        answerType: context.answerType,
                                        filters: {
                                            value: undefined,
                                            values: [],
                                            type: 'lt',
                                        },
                                        projection: context,
                                    };
                                }
                            }
                            resolve(context);
                        });
                    })
                );

                this.referenceContexts.push({
                    id: 'other-crow-filters',
                    items: otherAwaitedContexts,
                    masterId: null,
                    title: 'Overige filters',
                    checked: false,
                });
            }

            this.initialised = true;
            this.loading = false;
        },
        async getActiveOrganisation() {
            const activeProductsactiveOrganisation = [];

            this.activeProducts.forEach((product) => {
                activeProductsactiveOrganisation.push(product.slug);
            });

            return new Promise((resolve, reject) => {
                this.$apollo
                    .query({
                        query: gql`
                            query Organisations(
                                $first: Int
                                $skip: Int
                                $where: JSON
                                $sort: String
                                $caseSensitive: Boolean
                            ) {
                                organisations(
                                    first: $first
                                    skip: $skip
                                    where: $where
                                    sort: [$sort]
                                    caseSensitive: $caseSensitive
                                ) {
                                    id
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                AND: [
                                    {
                                        id: this.activeOrganisation,
                                    },
                                ],
                            },
                        },
                    })
                    .then((result) => {
                        resolve(result.data.organisations);
                    })
                    .catch((error) => error.message);
            });
        },
        async getOrganisations(organisationType) {
            return new Promise((resolve, reject) => {
                this.$apollo
                    .query({
                        query: gql`
                            query Organisations(
                                $first: Int
                                $skip: Int
                                $where: JSON
                                $sort: String
                                $caseSensitive: Boolean
                            ) {
                                organisations(
                                    first: $first
                                    skip: $skip
                                    where: $where
                                    sort: [$sort]
                                    caseSensitive: $caseSensitive
                                ) {
                                    id
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                AND: [
                                    {
                                        name__includes: this.filter.search || '',
                                    },
                                    organisationType !== 'Branch'
                                        ? {
                                              products__some: {
                                                  slug__contains: organisationType.toLowerCase(),
                                                  enabled: true,
                                              },
                                          }
                                        : {},
                                    organisationType !== 'Branch'
                                        ? {
                                              type: 'main',
                                          }
                                        : {
                                              type: 'branch',
                                          },
                                ],
                            },
                            first: 10,
                        },
                    })
                    .then((result) => {
                        resolve(result.data.organisations);
                    })
                    .catch((error) => reject(error.message));
            });
        },
        handleMouseDown(event) {
            const x = event.clientX;
            const width = window.innerWidth;
            if (width - x > 480) this.handleEmitShow();
        },
    },
    watch: {
        showFilterMenu: function () {
            if (!this.showFilterMenu) {
                document.removeEventListener('mousedown', this.handleMouseDown);
                return;
            }
            document.addEventListener('mousedown', this.handleMouseDown);
        },
    },
    created() {
        this.getIndicators();
    }
};
</script>

<style lang="scss" scoped>
.filter-menu {
    position: fixed;
    right: 0;
    top: 81px;
    width: 480px;
    transform: translateX(105%);
    transition: transform 0.4s ease;
    height: calc(100vh - 81px);
    background-color: white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    &.opened {
        transform: translateX(0);
    }

    .back {
        display: flex;
        justify-content: space-between;
        padding: 32px;
        border-bottom: 1px solid #dddddd;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            gap: 12px;
            height: 22px;

            p {
                font-weight: 500;
                width: 1000px;
            }
        }
        .right {
            .arrow-right {
                transform: rotate(90deg);
            }
        }

        &:hover {
            background-color: #f8f9fa;
            cursor: pointer;
        }
    }
}
</style>
