<template>
    <div class="page-padding">
        <div class="header">
            <div>
                <arrow-button color="#aeb5bc" @click="$router.push('/manage/projecttemplates')" direction="left">Projecttemplates</arrow-button>
            </div>
            <div v-if="creatingTemplate" class="inline-flex">
                <q-clear-input v-model="projectTemplate.name" class="input" type="text" size="extralarge" placeholder="Vul een projecttemplate in" @enter="handleCreateTemplate" autofocus></q-clear-input>
                <q-button :disabled="projectTemplate.name.length === 0" :loading="loadingCreatingTemplate" @click="handleCreateTemplate">Bevestig naam projecttemplate</q-button>
            </div>
            <h2 v-else>{{ projectTemplate.name }}</h2>
        </div>

        <div v-if="!creatingTemplate && (activeLabels.length > 0 || disabledLabels.length > 0)" class="actions-container">
            <div class="left">
                <q-input labelPosition="inside" v-model="searchingValue" variation="blank" size="medium" placeholder="Typ om te zoeken" @input="_handleFilterLabels">Zoekopdracht</q-input>
            </div>
            <div class="right">
                <div class="button-container">
                    <q-button size="medium" variation="primary" @click="handleAddLabel">Prestatie toevoegen</q-button>
                </div>
            </div>
        </div>

        <div v-else class="loading-state"></div>

        <q-zerostate
            v-if="!creatingTemplate && (activeLabels.length === 0 && disabledLabels.length === 0) && !loadingLabels"
            class="mt-xxl" 
            img="/statics/img/survey_gradient.svg"
        >
            <div style="max-width: 700px; text-align: center">
                <span>Om gebruik te kunnen maken van dit projecttemplate zul je eerst prestaties toe moeten voegen.</span>
                <q-button class="mt-m" style="margin-inline: auto;" size="medium" variation="primary" @click="handleAddLabel">Prestatie toevoegen</q-button>
            </div>
        </q-zerostate>

        <div v-else class="data-container">
            <div class="show-loader" :class="loadingLabels ? 'show' : 'hide'">
                <div class="center">
                    <div class="loader"></div>
                </div>
            </div>
            <context-rows
                v-if="sortedActiveLabels.length > 0"
                :labels="sortedActiveLabels"
                :allLabels="labels"
                :collapsedRowId="collapsedRowId"
                :searchingValue="searchingValue"
                :referencesOnly="!hasPDFull"
                dataName="prestatie"
                contextName="projecttemplate"
                noDisable
                @indicatorUpdated="handleIndicatorUpdated"
                @indicatorAdded="handleIndicatorsAdded"
                @indicatorDeleted="handleIndicatorDeleted"
                @indicatorCreated="handleIndicatorCreated"
                @toggleShowRow="toggleShowRow"
                @labelUpdated="handleLabelUpdated"
                @labelDeleted="handleLabelDeleted">
            </context-rows>
            <p v-else-if="!loadingLabels && !handleCreateTemplate" class="template-rows">Er zijn geen actieve prestaties gevonden</p>

            <div v-if="sortedDisabledLabels.length > 0" class="template-group">
                <h3>Gedeactiveerde prestaties</h3>
                <context-rows
                    class="template-rows"
                    :labels="sortedDisabledLabels"
                    :allLabels="labels"
                    :collapsedRowId="collapsedRowId" 
                    :loadingComponents="loadingComponents" 
                    :searchingValue="searchingValue"
                    :disableToggleRow="enableDragQuestions"
                    dataName="prestatie"
                    disabled
                    noDisable
                    @labelUpdated="handleLabelUpdated"
                    @labelDeleted="handleLabelDeleted"
                    @indicatorUpdated="handleIndicatorUpdated"
                    @indicatorCreated="handleIndicatorCreated"
                    @indicatorDeleted="handleIndicatorDeleted"
                ></context-rows>
            </div>

        </div>
        <create-label-modal 
            v-if="showAddLabelModal"
            :contextName="contextName"
            :disabledLabels="labels"
            @cancel="showAddLabelModal = false" 
            @created="handleLabelCreated"
            @added="handleLabelAdded">
        </create-label-modal>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue';
import gql from 'graphql-tag';
import ContextRows from './ContextRows.vue';
import CreateLabelModal from './CreateLabelModal.vue';
import _ from 'lodash';

import { GET_INDICATORS_WITH_TEMPLATES, GET_PROJECT_TEMPLATES } from '@/graphql/queries';
import { CREATE_PROJECTTEMPLATE, UPDATE_LABEL, PROJECTTEMPLATE_REMOVE_LABEL } from '@/graphql/mutations';
import { sortIndicators, getAnswerTypeLabel } from '@/assets/js/utils.js';

export default {
    name: 'project-template-management',
    components: {
        'arrow-button': ArrowButton,
        ContextRows,
        CreateLabelModal
    },
    data() {
        return {
            projectTemplate: {
                name: this.$route.query.name || ''
            },
            projecttemplates: [],
            labels: [],
            indicators: [],
            activeLabels: [],
            sortedActiveLabels: [],
            disabledLabels: [],
            sortedDisabledLabels: [],
            collapsedRowId: null,
            searchingValue: '',
            showAddLabelModal: false,
            loadingLabels: false,
            creatingTemplate: false,
            loadingCreatingTemplate: false, 
            contextName: 'projecttemplate', 
        }
    },
    methods: {
        getProjectTemplate() {
            this.loadingLabels = true;
            const projectId = this.$route.params.id;
            this.$apollo.query({
                query: gql`
                    query {
                        projecttemplate(id: "${projectId}"){
                            id
                            name
                            status
                            indicatorIds
                            labels {
                                id
                                name
                                description
                                masterId
                                status
                                order
                            }
                        }
                    }
                `,
                fetchPolicy: 'no-cache'
            })
            .then(async result => {
                this.projectTemplate = result.data.projecttemplate;

                const increment = 100;
                let indicators = [];

                while(true) {
                    const incrementIndicators = await this.getIndicators(increment, indicators.length);

                    indicators = [...indicators, ...incrementIndicators];
                    if(incrementIndicators.length < increment) break
                }

                this.projectTemplate.indicators = indicators;
                this.labels = this.projectTemplate.labels || [];
                this.indicators = indicators;
                this.parseLabels();

                this.loadingLabels = false;
            })
            .catch(error => {
                console.log(error);
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van het projecttemplate' });
                this.$router.push('/manage/projecttemplates');
                this.loadingLabels = false;
            })
        },
        async getProjectTemplates() {
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
            const projecttemplates = await this.$apollo.query({
                query: GET_PROJECT_TEMPLATES,
                variables: {
                    where: { AND: [
                        { organisationId },
                        { status__in: ["active","disabled"] },
                        { id__nin: "crow-default" }, 
                        { masterId__nin: "crow-default" }
                    ]}
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => result.data.projecttemplates);

            this.projecttemplates = projecttemplates;
        },
        async getIndicators(first, skip) {
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            const indicatorIds = this.projectTemplate.indicatorIds

            try {
                const result = await this.$apollo.query({
                    query: GET_INDICATORS_WITH_TEMPLATES,
                    variables: {
                        organisationId,
                        where: {
                            id__in: indicatorIds
                        },
                        first,
                        skip
                    },
                    fetchPolicy: 'no-cache'
                })

                return result.data.indicators_with_templates

            } catch (error) {
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de vragen en kenmerken' });
                this.$router.push('/manage/projecttemplates');
                this.loadingLabels = false;
            }
        },
        parseQuestions(questions) {
            if(!questions) return []
            return questions.map(question => {
                question.answerTypeLabel = getAnswerTypeLabel(question.displayType);
                question.enabled = question.enabled ?? true;
                question.required = question.required ?? true;
                question.mandatory = question.mandatory ?? true;
                question.canFillComment = question.canFillComment ?? true;
                question.canFillNotApplicable = question.canFillNotApplicable ?? true;
                if(question.status === 'disabled') question.__customStyling = 'color: #757575;';
                else question.__customStyling = null;
                return question
            })
        },
        parseLabels() {
            const translatedNames = ['Project_details', 'Crow_project_details'];
            this.indicators = sortIndicators(this.indicators);
            this.labels = this.labels.sort((a,b) => a.order - b.order)

            const labels = this.labels.map(label => {
                if(translatedNames.includes(label.name)) label.name = this.$t(label.name);
                const references = this.indicators.filter(indicator => indicator.type === 'reference' && indicator.labelIds.includes(label.id))
                const questions = this.indicators.filter(indicator => indicator.type === 'question' && indicator.labelIds.includes(label.id))

                return {
                    ...label,
                    questions: this.parseQuestions(questions),
                    references: this.parseQuestions(references)
                }
            });

            const activeLabels = labels.filter(label => label.status === 'active');
            const disabledLabels = labels.filter(label => label.status === 'disabled');

            this.activeLabels = [...activeLabels];
            this.sortedActiveLabels = [...activeLabels]
            this.disabledLabels = disabledLabels;
            this.sortedDisabledLabels = disabledLabels;

            this.labels = labels
        },
        toggleShowRow(row) {
            if(this.collapsedRowId !== row.id) {
                this.collapsedRowId = row.id;
                this.scrollToLabel(row.id);
            }
            else this.collapsedRowId = null;
        },
        handleAddLabel() {
            this.showAddLabelModal = true;
        },
        _handleFilterLabels: _.debounce(function() {
            this.handleFilterLabels()
        }, 250),
        handleFilterLabels() {
            this.sortedActiveLabels = [...this.activeLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
            this.sortedDisabledLabels = [...this.disabledLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
        },
        handleCreateTemplate() {
            if(this.labelNameExists) return this.$store.commit('notify', { type: 'info', message: 'Er bestaat al een projecttemplate met deze naam' })

            this.loadingCreatingTemplate = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.$apollo.mutate({
                mutation: CREATE_PROJECTTEMPLATE,
                variables: {
                    name: this.projectTemplate.name,
                    organisationId
                }
            })
            .then(result => {
                this.creatingTemplate = false;
                this.loadingCreatingTemplate = false;
                this.$store.commit('notify', { type: 'success', message: 'Projecttemplate is succesvol aangemaakt' })
                this.$router.push(`/manage/projecttemplates/${result.data.projecttemplate_create.id}?name=${this.projectTemplate.name}`)
            })
            .catch(error => {
                console.log(error)
                this.loadingCreatingTemplate = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanmaken van het projecttemplate' })
            })
        },
        handleLabelCreated(label) {
            this.showAddLabelModal = false;
            label.questions = [];
            label.references = [];
            this.labels.push(label);
            this.parseLabels();
            this.collapsedRowId = label.id;
            this.scrollToLabel(label.id);
        },
        handleLabelAdded(label, indicators = []) {
            const projecttemplateId = this.projectTemplate.id || this.$route.params.id;
            indicators = indicators.map(indicator => {
                let templateIds = indicator.projecttemplateIds || [];
                let templates = indicator.projecttemplates || [];
                if(!templateIds.includes(projecttemplateId)) templateIds.push(projecttemplateId)
                if(!templates.find(template => template.id === projecttemplateId)) templates.push({
                    id: projecttemplateId,
                    name: this.projectTemplate.name
                })
                return indicator
            })
            this.labels.push(label);
            this.labels = this.labels.sort((a, b) => a.order - b.order);
            indicators.forEach(indicator => {
                this.indicators.push(indicator);
            })
            this.parseLabels();
            this.collapsedRowId = label.id;
            this.scrollToLabel(label.id);
        },
        handleLabelUpdated(label) {
            this.$apollo.mutate({
                mutation: UPDATE_LABEL,
                variables: {
                    id: label.id,
                    name: label.name,
                    description: label.description,
                    status: label.status
                }
            })
            .then(result => {
                this.labels = this.labels.map(_label => {
                    if(_label.id === label.id) return label
                    return _label
                })
                this.parseLabels();
                this.$store.commit('notify', { type: 'success', message: 'De wijzigingen zijn doorgevoerd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de wijzigingen' })
            })
        },
        handleLabelDeleted(label) {
            this.labels.map(_label => {
                if (_label.id === label.id) _label.loading = true;
                return _label; 
            });

            this.parseLabels();

            this.$apollo.mutate({
                mutation: PROJECTTEMPLATE_REMOVE_LABEL,
                variables: {
                    id: this.$route.params.id,
                    labelId: label.id
                }
            })
            .then(result => {
                this.labels = this.labels.filter(_label => _label.id !== label.id);
                this.indicators = this.indicators.filter(indicator => indicator.labelIds[0] !== label.id);
                this.parseLabels();
                this.$store.commit('notify', { type: 'success', message: 'Prestatie is succesvol verwijderd' })
            })
            .catch(error => {
                this.labels.map(_label => {
                    if (_label.id === label.id) _label.loading = false;
                    return _label; 
                });
                this.parseLabels();
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van de prestatie' })
            })
        },
        handleIndicatorDeleted(indicator) {
            const indicatorIndex = this.indicators.findIndex(_indicator => _indicator.id === indicator.id);
            if(indicatorIndex === -1) return
            this.indicators.splice(indicatorIndex, 1);

            const lastOfLabel = this.indicators.filter(_indicator => _indicator.labelIds[0] === indicator.labelIds[0]).length === 0;
            if(lastOfLabel) {
                const labelIndex = this.labels.findIndex(label => label.id === indicator.labelIds[0]);
                if(labelIndex === -1) return
                this.labels.splice(labelIndex, 1);
            }
            this.parseLabels();
        },
        handleIndicatorCreated(indicator) {
            this.indicators.push(indicator);
            this.parseLabels();
        },
        handleIndicatorUpdated(indicator) {
            const indicatorIndex = this.indicators.findIndex(_indicator => _indicator.id === indicator.id);
            if(indicatorIndex === -1) return
            
            const indicatorIsInProjecttemplate = indicator.projecttemplateIds.includes(this.$route.params.id);
            if(indicatorIsInProjecttemplate) this.indicators[indicatorIndex] = indicator;
            else {
                this.indicators = this.indicators.filter(_indicator => _indicator.id !== indicator.id);

                const noIndicatorsInLabel = this.indicators.filter(_indicator => _indicator.labelIds[0] === indicator.labelIds[0]).length === 0;
                if(noIndicatorsInLabel) this.labels = this.labels.filter(label => label.id !== indicator.labelIds[0]);
            }

            this.parseLabels();
        },
        async scrollToLabel(rowId) {
            await new Promise(r => setTimeout(r, 300))
            const element = document.getElementById(`row-${rowId}`);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        handleIndicatorsAdded(indicators) {
            indicators = indicators.map(indicator => {
                const templateIds = indicator.projecttemplateIds || [];
                const templates = indicator.projecttemplates || [];
                templateIds.push(this.projectTemplate.id);
                templates.push(this.projectTemplate);
                indicator.projecttemplateIds = templateIds;
                indicator.projecttemplates = templates;
                return indicator
            });
            this.indicators = [ ...this.indicators, ...indicators ];
            this.parseLabels();
        }
    },
    computed: {
        hasPDFull: function() {
            return this.$store.getters.hasPDFullProduct
        },
        labelNameExists: function() {
            if(!this.creatingTemplate) return false
            return this.projecttemplates.some(template => template.name.toLowerCase().trim() === this.projectTemplate.name.toLowerCase().trim())
        }
    },
    created() {
        this.getProjectTemplates();
        const templateId = this.$route.params.id;
        if(templateId === 'create') return this.creatingTemplate = true;
        this.getProjectTemplate();
    }
}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';

.page-padding {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .inline-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .input {
            width: 560px;
        }
    }

}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 6px;

    .left {
        min-width: 300px;
    }

    .right {
        display: flex;
        gap: 30px;
    }
}

.labels-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.template-group {
    padding-top: 36px;
    .template-rows {
        margin-top: 20px;
    }
}

// animations

.show-loader {
    overflow: hidden;
    transition: max-height .3s ease;

    &.show {
        max-height: 120px;
    }
    &.hide {
        max-height: 0;
        .center .loader {
            transition: .2s ease;
            scale: 0.5;
            opacity: 0;
        }
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 40px;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 40px;
    $loader-border-size: 3px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>